import React, { useState } from 'react';
import { Button, message } from 'antd';
import { updateStaff } from '../../Services/API';
/**
 *
 * @param {String} type
 * @param {String} staff_id
 * @param {String} file_id
 * @param {Function} handler
 * @description Botón que elimina un archivo de la compañia o personal
 */
const RemoveButton = ({ type = 'attachments', staff_id, file_id, handler }) => {
  const [_loading, setLoading] = useState(false);
  async function fetchFile() {
    setLoading(true);
    let response = await updateStaff(staff_id, {
      $pull: {
        [type]: {
          _id: file_id,
        },
      },
    });
    if (response.ok) {
      handler(response.data);
      message.success('Archivo eliminado');
    }
    setLoading(false);
  }
  return (
    <Button
      danger
      type="link"
      key="list-loadmore-edit"
      disabled={_loading}
      onClick={fetchFile}
    >
      {_loading ? 'Eliminando...' : 'Eliminar'}
    </Button>
  );
};

export default RemoveButton;
