import React from 'react';
import { Badge } from 'antd';
import IconRender from '../Components/IconRender';
export const userColumn = [
  {
    title: 'Nombre Completo',
    dataIndex: 'full_name',
  },
  {
    title: 'Email',
    dataIndex: 'email',
  },
  {
    title: 'Estatus',
    dataIndex: 'status',
    render: (column) =>
      column ? (
        <Badge status="success" text="Activo" />
      ) : (
        <Badge status="error" text="Inactivo" />
      ),
  },
];
//TODO agregar iconos y color
export const fileTypesColumn = [
  {
    title: 'Nombre',
    dataIndex: 'name',
  },
  {
    title: 'Descripción',
    dataIndex: 'description',
    render: (column) => column || 'Sin descripción',
  },
  {
    title: 'Estatus',
    dataIndex: 'status',
    render: (column) =>
      column ? (
        <Badge status="success" text="Activo" />
      ) : (
        <Badge status="error" text="Inactivo" />
      ),
  },
];

export const departmentColumn = [
  {
    title: 'Nombre',
    dataIndex: 'name',
  },
  {
    title: 'Icono',
    dataIndex: 'icon',
    render: (column) =>
      column ? (
        <div>
          <IconRender
            iconName={column}
            size={14}
            strokeWidth={1}
            className="anticon"
            style={{ marginRight: 10 }}
          />
          {column}
        </div>
      ) : (
        'Sin Icono'
      ),
  },
  {
    title: 'Estatus',
    dataIndex: 'status',
    render: (column) =>
      column ? (
        <Badge status="success" text="Activo" />
      ) : (
        <Badge status="error" text="Inactivo" />
      ),
  },
];

export const companyColumn = [
  {
    title: 'Nombre',
    dataIndex: 'name',
  },
  {
    title: 'Nombre completo',
    dataIndex: 'full_name',
  },
  {
    title: 'RFC',
    dataIndex: 'rfc',
  },
];
