import { useState, useEffect, useCallback } from 'react';
import {
  getDepartments,
  getMemberDepartments,
  addMemberToDepartment,
  removeMemberInDepartment,
} from '../Services/API';
import { message } from 'antd';

export const useFetchUserPermission = (user_id) => {
  const [loading, setLoading] = useState(false);
  const [softLoading, setSoft] = useState(false);
  const [all, setAll] = useState([]); // Los permisos generales
  const [assigned, setAssigned] = useState([]); // Los permisos asignados
  const fetch = useCallback(async () => {
    if (user_id) {
      setLoading(true);
      await getDepartments(
        [{ field: 'members.user_id', value: user_id, operator: '$ne' }],
        0,
        500
      ).then((response) => {
        if (response.ok) {
          setAll(response.data.data);
        }
      });
      await getMemberDepartments(user_id).then((response) => {
        if (response.ok) {
          setAssigned(response.data.data);
        }
      });
      setLoading(false);
    }
  }, [setAll, setAssigned, setLoading, user_id]);
  useEffect(() => {
    fetch();
  }, [fetch]);
  async function addModule(module) {
    setSoft(true);
    let response = await addMemberToDepartment(module, user_id);
    if (response.ok) {
      message.success('Modulo agregado');
      setSoft(false);
      await fetch();
    } else {
      message.error('No se pudo agregar el módulo');
      setSoft(false);
    }
  }
  async function removeModule(module, id) {
    setSoft(true);
    let response = await removeMemberInDepartment(module, id);
    if (response.ok) {
      message.success('Modulo removido');
      setSoft(false);
      await fetch();
    } else {
      message.error('No se pudo remover el módulo');
      setSoft(false);
    }
  }
  return [assigned, all, loading, softLoading, addModule, removeModule];
};
