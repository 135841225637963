import React from 'react';
import { Form, Input, InputNumber, Tabs, DatePicker, Select } from 'antd';

const { TabPane } = Tabs;
const regRFC = new RegExp(/^([A-ZÑ&]{3,4}) ?(?:- ?)?(\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])) ?(?:- ?)?([A-Z\d]{2})([A\d])$/);
const regCURP = new RegExp(/^([A-Z][AEIOUX][A-Z]{2}\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])[HM](?:AS|B[CS]|C[CLMSH]|D[FG]|G[TR]|HG|JC|M[CNS]|N[ETL]|OC|PL|Q[TR]|S[PLR]|T[CSL]|VZ|YN|ZS)[B-DF-HJ-NP-TV-Z]{3}[A-Z\d])(\d)$/);

const inputFormatter = value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
const inputParser = value => value.replace(/\$\s?|(,*)/g, '');

const StaffForm = ({ formRef, onSubmit, loading, ...props }) => {
  return (
    <Form
      form={formRef}
      onFinish={onSubmit}
      labelCol={{ span: 8 }}
      wrapperCol={{ span: 16 }}
      initialValues={{ status: 1, gender: 'M' }}
      {...props}
    >
      <Tabs defaultActiveKey="1">
        <TabPane tab="Datos generales" key="1">
          <Form.Item
            label="Nombre Completo"
            name="full_name"
            labelAlign="left"
            rules={[
              {
                required: true,
                message: 'Por favor ingrese el nombre completo!',
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="CURP"
            name="curp"
            labelAlign="left"
            hasFeedback
            rules={[
              { required: true, message: 'Por favor ingrese el CURP!' },
              ({ getFieldValue }) => ({
                validator(rule, value) {
                  if (value && regCURP.test(value)) {
                    return Promise.resolve();
                  }
                  return Promise.reject('CURP no valido');
                },
              }),
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Identificador (Interno)"
            name="key"
            labelAlign="left"
            rules={[
              {
                required: true,
                message: 'Por favor ingrese la clave!',
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="RFC"
            name="rfc"
            labelAlign="left"
            hasFeedback
            rules={[
              { required: true, message: 'Por favor ingrese el RFC!' },
              ({ getFieldValue }) => ({
                validator(rule, value) {
                  if (value && regRFC.test(value)) {
                    return Promise.resolve();
                  }
                  return Promise.reject('RFC no valido');
                },
              }),
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Fecha de nacimiento"
            name="birthday"
            labelAlign="left"
          >
            <DatePicker
              placeholder="Seleccione una fecha"
              style={{ width: '100%' }}
              format="DD/MM/YYYY"
            />
          </Form.Item>
          <Form.Item label="Genero" name="gender" labelAlign="left">
            <Select style={{ width: '100%' }}>
              <Select.Option value='M'>Masculino</Select.Option>
              <Select.Option value='F'>Femenino</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item label="Estatus" name="status" labelAlign="left">
            <Select style={{ width: '100%' }}>
              <Select.Option value={0}>Inactivo</Select.Option>
              <Select.Option value={1}>Activo</Select.Option>
            </Select>
          </Form.Item>
        </TabPane>
        <TabPane tab="Información personal" key="3">
          <Form.Item label="Departamento" name="department" labelAlign="left">
            <Input />
          </Form.Item>
          <Form.Item
            label="Tipo de contrato"
            name="type_contract"
            labelAlign="left"
          >
            <Select style={{ width: '100%' }}>
              <Select.Option value="Indefinido">Indefinido</Select.Option>
              <Select.Option value="Temporal">Temporal</Select.Option>
              <Select.Option value="Por obra o servicio">
                Por obra o servicio
              </Select.Option>
              <Select.Option value="Eventual">Eventual</Select.Option>
              <Select.Option value="De interinidad">
                De interinidad
              </Select.Option>
              <Select.Option value="Relevo">Relevo</Select.Option>
              <Select.Option value="Formación y aprendizaje">
                Formación y aprendizaje
              </Select.Option>
              <Select.Option value="Prácticas">Prácticas</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item
            label="Fecha de ingreso"
            name="date_admission"
            labelAlign="left"
          >
            <DatePicker
              placeholder="Seleccione una fecha"
              style={{ width: '100%' }}
              format="DD/MM/YYYY"
            />
          </Form.Item>
          <Form.Item
            label="Tipo de jornada"
            name="type_working"
            labelAlign="left"
          >
            <Select style={{ width: '100%' }}>
              <Select.Option value="Diurna">Diurna</Select.Option>
              <Select.Option value="Nocturna">Nocturna</Select.Option>
              <Select.Option value="Mixta">Mixta</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item label="Dirección" name="address" labelAlign="left">
            <Input />
          </Form.Item>
          <Form.Item label="NSS" name="nss" labelAlign="left">
            <Input />
          </Form.Item>
        </TabPane>
        <TabPane tab="Nómina" key="2">
          <Form.Item
            label="Método de pago"
            name="payment_method"
            labelAlign="left"
          >
            <Select style={{ width: '100%' }}>
              <Select.Option value="Efectivo">Efectivo</Select.Option>
              <Select.Option value="Cheque">Cheque</Select.Option>
              <Select.Option value="Transferencia">Transferencia</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item label="Percepciones" name="perceptions" labelAlign="left">
            <InputNumber
              formatter={inputFormatter}
              parser={inputParser}
            />
          </Form.Item>
          <Form.Item label="Deducciones" name="deductions" labelAlign="left">
            <InputNumber
              formatter={inputFormatter}
              parser={inputParser}
            />
          </Form.Item>
          <Form.Item
            label="Salario diario"
            name="daily_salary"
            labelAlign="left"
          >
            <InputNumber
              formatter={inputFormatter}
              parser={inputParser}
            />
          </Form.Item>
          <Form.Item
            label="Salario diario integrado"
            name="integrated_salary"
            labelAlign="left"
          >
            <InputNumber
              formatter={inputFormatter}
              parser={inputParser}
            />
          </Form.Item>
          <Form.Item label="Total sueldo" name="total_salary" labelAlign="left">
            <InputNumber
              formatter={inputFormatter}
              parser={inputParser}
            />
          </Form.Item>
        </TabPane>
        <TabPane tab="Contacto emergencia" key="4">
          <Form.Item label="Tipo de sangre" name="blood_type" labelAlign="left">
            <Select style={{ width: '100%' }}>
              <Select.Option value="O-">O negativo</Select.Option>
              <Select.Option value="O+">O positivo</Select.Option>
              <Select.Option value="A-">A negativo</Select.Option>
              <Select.Option value="A+">A positivo</Select.Option>
              <Select.Option value="B-">B negativo</Select.Option>
              <Select.Option value="B+">B positivo</Select.Option>
              <Select.Option value="AB-">AB negativo</Select.Option>
              <Select.Option value="AB+">AB positivo</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item label="Nombre" name="contact_name" labelAlign="left">
            <Input />
          </Form.Item>
          <Form.Item label="Teléfono" name="contact_phone" labelAlign="left">
            <InputNumber />
          </Form.Item>
          <Form.Item label="Dirección" name="contact_address" labelAlign="left">
            <Input />
          </Form.Item>
        </TabPane>
      </Tabs>
    </Form>
  );
};

export default StaffForm;
