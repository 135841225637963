import React from 'react';
import { Row, Card, Form } from 'antd';

//Hooks
import { useAuth } from '../../Hooks/Auth.hook';
//Forms
import LoginForm from '../../Components/Form/Login.form';


const LoginScreen = (props) => {
  const [formRef] = Form.useForm();
  const [{ loading }, auth] = useAuth();
  async function login({ email, password }) {
    await auth(email, password);
  }
  return (
    <div className="login-screen  fadeIn ">
      <Row
        type="flex"
        align="middle"
        justify="center"
        className="px-3 bg-white mh-page"
      >
        <Card
          className="shadow-sm rounded overflow-hidden wrapper"
          bordered={false}
          bodyStyle={{ padding: 0 }}
        >
          <div className="">
            <div className="text-center my-5">
              <span className="brand">
                <img src="/images/logo.svg" width="24px" alt="MLC" />
                <strong className="mx-1 text-black">MLC</strong>
              </span>
              <h2 className="font-weight-bold" style={{ display: 'block' }}>
                Inicio de sesion
              </h2>
            </div>
            <div style={{ width: '80%', margin: 'auto' }}>
              <LoginForm formRef={formRef} onSubmit={login} loading={loading} />
            </div>
          </div>
        </Card>
      </Row>
    </div>
  );
};

export default LoginScreen;
