function Export(data) {
  this._data = data;
  this.objectToSemicolons = function () {
    const colsHead = Object.keys(this._data[0])
      .map(key => [key])
      .join(',');
    const colsData = this._data
      .map(obj => [
        // Obj === row
        Object.keys(obj)
          .map(col => [
            obj[col], // Row[column]
          ])
          .join(','), // Join the row with ';'
      ])
      .join('\n'); // End of row

    return `${colsHead}\n${colsData}`;
  };
  /**
   * Download file
   * @param {*} output the content
   * @param {String} fileName
   */
  this.downloadFile = function (output, fileName) {
    const link = document.createElement('a');
    document.body.appendChild(link);
    link.download = fileName;
    link.href = output;
    link.click();
  };
  this.exportToCSV = function (fileName = 'export.csv') {
    if (
      typeof fileName !== 'string' ||
      Object.prototype.toString.call(fileName) !== '[object String]'
    ) {
      throw new Error('Invalid input type: exportToCSV(String)');
    }
    const computedCSV = new Blob([this.objectToSemicolons()], {
      type: 'text/csv;charset=utf-8',
    });
    const csvLink = window.URL.createObjectURL(computedCSV);
    this.downloadFile(csvLink, fileName);
  };
}

export function exportCSV(data, fn, type) {
  var xls = new Export(data, type);
  xls.exportToCSV(fn + '.csv');
}
