import immutable from 'seamless-immutable';
import { createReducer, createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  rolesRequest: [],
  rolesSuccess: ['roles'],
  rolesFailure: ['error'],
});
const INITIAL_STATE = immutable({
  loading: false,
  error: false,
  errorMessage: null,
  roles: { data: [] },
});

const request = (state, action) =>
  state.merge({ loading: true, error: false, errorMessage: null });

const failure = (state, action) => {
  let { error } = action;
  return state.merge({
    loading: false,
    error: true,
    errorMessage: error,
  });
};
const success = (state, action) => {
  let { roles } = action;
  return state.merge({
    loading: false,
    error: false,
    errorMessage: null,
    roles,
  });
};

const HANDLERS = {
  [Types.ROLES_REQUEST]: request,
  [Types.ROLES_SUCCESS]: success,
  [Types.ROLES_FAILURE]: failure,
};

export const Roles = Creators;
export const rolesTypes = Types;
export default createReducer(INITIAL_STATE, HANDLERS);
