import React, { useState } from 'react';
import {
  Card,
  Skeleton,
  Divider,
  Menu,
  Table,
  Result,
  Button,
  Modal,
  Form,
  message,
} from 'antd';
import Permisos from '../../Components/UserList/Permisos';

//Hooks
import { useFetchRoles } from '../../Hooks/Roles.hook';
import { useAuth } from '../../Hooks/Auth.hook';
import { useQueryUser } from '../../Hooks/Users.hook';
import { userColumn } from '../../Share/Columns';
import UserForm from '../../Components/Form/User.form';
import { updateUser } from '../../Services/API';
import Filter from '../../Components/Dashboard/Filter';

const UserList = () => {
  const [roles, loading] = useFetchRoles([
    { field: '$select[]', value: '_id' },
    { field: '$select[]', value: 'group' },
    { field: '$select[]', value: 'name' },
  ]);
  const [auth] = useAuth();
  const [users, loadingUser, , query] = useQueryUser();
  const [key, setKey] = useState(null);
  const [modal, setModal] = useState(false);
  const [modalUser, setModalUser] = useState(false);
  const [modalUserLoading, setMLoading] = useState(false);
  const [formRef] = Form.useForm();
  const [user, setUser] = useState({});
  const columns = [
    ...userColumn,
    {
      title: '',
      dataIndex: '_id',
      render: (data, row) => (
        <>
          {['admin', 'leader'].indexOf(auth.role.rol) > -1 && (
            <Button
              type="dashed"
              style={{ marginRight: 10 }}
              onClick={() => {
                setUser(row);
                formRef.setFieldsValue(row);
                setModalUser(true);
              }}
            >
              Editar
            </Button>
          )}
          <Button
            type="primary"
            onClick={() => {
              setUser(row);
              setModal(true);
            }}
          >
            Permisos
          </Button>
        </>
      ),
    },
  ];
  const filterRoles = () => {
    let rol = auth.role.rol;
    switch (rol) {
      case 'admin':
        return roles.filter((r) => r.group !== 'open-endpoints');
      case 'leader':
        return roles.filter(
          (r) =>
            r.group !== 'admin' &&
            r.group !== 'leader' &&
            r.group !== 'open-endpoints'
        );
      default:
        return roles.filter(
          (r) =>
            r.group !== 'admin' &&
            r.group !== 'leader' &&
            r.group !== 'employee' &&
            r.group !== 'open-endpoints'
        );
    }
  };
  const selectKey = (e) => {
    setKey(e.key);
    query([{ field: 'rol_id', value: e.key }]);
  };
  const _handleSubmit = async (values) => {
    setMLoading(true);
    let response = await updateUser(user._id, values);
    if (response.ok) {
      message.success('Usuario actualizado');
      setUser({});
      setModalUser(false);
      query([{ field: 'rol_id', value: key }]);
    }
    setMLoading(false);
  };
  if (loading || roles.length === 0) {
    return (
      <Card>
        <Skeleton loading />
      </Card>
    );
  }
  return (
    <Card className="slideInLeft">
      <Divider orientation="left">Usuarios</Divider>
      <Modal
        visible={modal}
        onClose={() => setModal(false)}
        onCancel={() => setModal(false)}
        title="Permisos"
        footer={[
          <Button type="primary" onClick={() => setModal(false)} key="ok">
            Cerrar
          </Button>,
        ]}
      >
        <Permisos user={user} />
      </Modal>
      <Modal
        visible={modalUser}
        onClose={() => setModalUser(false)}
        onCancel={() => setModalUser(false)}
        onOk={() => {
          formRef.submit();
        }}
        title="Actualizar usuario"
        okButtonProps={{ loading: modalUserLoading }}
      >
        <UserForm
          edit
          formRef={formRef}
          onSubmit={_handleSubmit}
          rol={auth.role.rol}
        />
      </Modal>
      <div>
        <Menu
          mode="horizontal"
          onSelect={selectKey}
          selectedKeys={[key]}
          style={{ marginBottom: 10 }}
        >
          {filterRoles().map((row) => (
            <Menu.Item key={row._id}>{row.name}</Menu.Item>
          ))}
        </Menu>
        {key !== null ? (
          <>
            <Filter
              handler={(values = []) =>
                query([{ field: 'rol_id', value: key }, ...values])
              }
              fields={[
                {
                  title: 'Nombre Completo',
                  dataIndex: 'full_name',
                },
                {
                  title: 'Email',
                  dataIndex: 'email',
                },
                {
                  title: 'Estatus',
                  dataIndex: 'status',
                },
              ]}
            />
            <Table
              dataSource={users.data}
              columns={
                auth.role.rol === 'admin' || auth.role.rol === 'leader'
                  ? columns
                  : userColumn
              }
              loading={loadingUser}
              rowKey={(row) => row._id}
              className="responsive-table"
              locale={{ emptyText: 'Sin registros' }}
              pagination={{
                total: users.total,
                current: users.skip / 10 + 1,
                onChange: (e) =>
                  query([{ field: 'rol_id', value: key }], (e - 1) * 10),
              }}
            />
          </>
        ) : (
          <Result title="Seleccione un tipo de usuario para continuar" />
        )}
      </div>
    </Card>
  );
};

export default UserList;
