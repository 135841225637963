import React from 'react';
import { Layout, Avatar } from 'antd';
import { BarChart } from 'react-feather';

//Hooks
import { useMobile } from '../../Hooks/Responsive.hook';
import { useAuth } from '../../Hooks/Auth.hook';
import { useDrawer } from '../../Hooks/App.hook';

const Header = () => {
  const [mobile] = useMobile();
  const [, drawer] = useDrawer();
  const [{ user }] = useAuth();
  function _clipName(name = '') {
    let words = name.split(' '),
      clip = '';
    for (let word of words) {
      clip += word[0];
    }
    return clip.toLocaleUpperCase();
  }
  return (
    <Layout.Header className="header">
      <div className="left-flex ">
        {mobile && (
          <span className="trigger">
            <BarChart
              size={25}
              strokeWidth={1}
              className="menu-bar"
              onClick={() => drawer(true)}
            />
          </span>
        )}
        <div className="logo">
          <span className="brand">
            <img src="/images/logo.svg" width="24px" alt="MLC" />
            <strong className="mx-1 text-black">MLC</strong>
          </span>
        </div>
      </div>

      <div>
        <div className="user-avatar">
          <Avatar size="default">
            {_clipName(user.full_name || user.name)}
          </Avatar>
        </div>
      </div>
    </Layout.Header>
  );
};

export default Header;
