import React, { useState } from 'react';
import { Col, Row, Select, Button, Popconfirm, message } from 'antd';
import { Trash } from 'react-feather';

//Hooks
import { useQueryUser } from '../../Hooks/Users.hook';
import { createAssignments, deleteAssignments } from '../../Services/API';

let timeout = null;
const AssignmentManager = ({
  assignments,
  handler,
  client_id,
  department_id,
  roles,
  loadingRoles,
}) => {
  const [loading, setLoading] = useState(false);
  const [users, loadingUser, , query] = useQueryUser();
  const [selected, setSelected] = useState(null);

  const _renderAssignments = () =>
    assignments.map((row) => {
      let load = false;
      const _handleDelete = async () => {
        load = true;
        let response = await deleteAssignments(row._id);
        if (response.ok) {
          message.success('Asignación eliminada, actualizando datos...');
          handler();
        } else {
          message.error('Hubo un error eliminando la asignación');
        }
        load = false;
      };
      return (
        <Row key={row._id} justify="space-between" className="employee">
          <Col>
            <span className="employee-name">{row.employee.full_name}</span>
          </Col>
          <Col>
            <Popconfirm
              title="¿Estás seguro de que quieres elimnar esta asignación？"
              okText="Si"
              cancelText="No"
              onConfirm={_handleDelete}
            >
              <Button
                danger
                loading={load}
                type="primary"
                icon={<Trash size={18} className="anticon" />}
              />
            </Popconfirm>
          </Col>
        </Row>
      );
    });
  const _handleSearchUser = (value) => {
    if (timeout) {
      clearTimeout(timeout);
      timeout = null;
    }
    timeout = setTimeout(async () => {
      await query([
        { field: 'full_name', value: value, operator: '$regex' },
        { field: 'full_name', value: 'i', operator: '$options' },
        ...assignments.map((row) => ({
          field: '_id',
          value: row.employee._id,
          operator: '$nin',
        })),
        ...roles.map((role) => ({
          field: 'rol_id',
          value: role._id,
          operator: '$or',
        })),
      ]);
    }, 700);
  };
  const _handleAddAssignment = async () => {
    setLoading(true);
    setSelected(null);
    let response = await createAssignments({
      user_id: selected,
      department_id,
      client_id,
      status: 1,
    });
    if (response.ok) {
      message.success('Se ha reliazado la asignación, actualizando...');
      handler();
    } else {
      message.error('Error realizando la asignación');
    }
    setLoading(false);
  };
  return (
    <div>
      <Row justify="space-between" style={{ marginBottom: 20 }}>
        <Col>
          <Select
            placeholder="Buscar empleado / lider"
            showSearch
            showArrow={false}
            filterOption={false}
            value={selected}
            notFoundContent={null}
            loading={loadingUser}
            disabled={loadingRoles}
            style={{ width: 200 }}
            onSearch={_handleSearchUser}
            onChange={(e) => setSelected(e)}
          >
            {users.data.map((user) => (
              <Select.Option value={user._id} key={user._id}>
                {user.full_name}
              </Select.Option>
            ))}
          </Select>
        </Col>
        <Col>
          <Button
            type="primary"
            onClick={_handleAddAssignment}
            disabled={selected === null}
            loading={loading}
          >
            Agregar
          </Button>
        </Col>
      </Row>
      <div className="assignments-content">
        {assignments.length === 0 ? 'Sin asignaciones' : _renderAssignments()}
      </div>
    </div>
  );
};

export default AssignmentManager;
