import React from 'react';
import { EyeOutlined } from '@ant-design/icons';

import { getServer } from '../../Utils/url';
import { useAuth } from '../../Hooks/Auth.hook';

const PreviewButton = ({ path, fileName, fileType }) => {
  const [auth] = useAuth();

  if (!fileType) {
    return <span></span>;
  }

  return (
    <>
      {fileType.indexOf('image') > -1 || fileType.indexOf('pdf') > -1 ? (
        <a
          target="_blank"
          rel="noopener noreferrer"
          title={fileName}
          href={`${getServer()}/upload?target=${path}&token=${auth.token}`}
        >
          <EyeOutlined style={{ fontSize: 20 }} />
        </a>
      ) : (
        ''
      )}
    </>
  );
};

export default PreviewButton;
