import React, { useState } from 'react';
import {
  Divider,
  Card,
  Alert,
  Row,
  Button,
  Table,
  Form,
  Modal,
  message,
  Col,
} from 'antd';
import { useFetchLabels } from '../../Hooks/Labels.hook';
import LabelForm from '../../Components/Form/Label.form';
import { updateLabel, createLabel, deleteLabel } from '../../Services/API';
import Filter from '../../Components/Dashboard/Filter';

const Etiquetas = () => {
  const [modalLoading, setModalLoading] = useState(false);
  const [labelId, setLabelId] = useState(null);
  const [labels, loading, , query, update] = useFetchLabels([
    { field: '$sort', value: true },
  ]);
  const [formRef] = Form.useForm();
  const [modal, setModal] = useState(false);
  const columns = [
    {
      title: 'Slug',
      dataIndex: 'slug',
    },
    {
      title: 'Lenguaje',
      dataIndex: 'language',
    },
    {
      title: 'País',
      dataIndex: 'country',
    },
    {
      title: 'Sección',
      dataIndex: 'section',
    },
    {
      title: '',
      dataIndex: '_id',
      width: 200,
      render(e, row) {
        return (
          <Row justify="space-between" gutter={[10, 10]}>
            <Button
              type="primary"
              onClick={() => {
                setLabelId(e);
                formRef.setFieldsValue(row);
                setModal(true);
              }}
            >
              Ver
            </Button>
            <Button
              type="primary"
              danger
              onClick={() =>
                Modal.confirm({
                  title: '¿Quieres borrar esta etiqueta?',
                  content: 'Puede ocasionar fallas el el sistema',
                  okText: 'Si',
                  cancelText: 'No',
                  async onOk() {
                    let response = await deleteLabel(e);
                    if (response.ok) {
                      message.success('Etiqueta eliminada');
                      update();
                    } else {
                      message.error(
                        'Error eliminando la etiqueta: ' + response.data.message
                      );
                    }
                  },
                })
              }
            >
              Eliminar
            </Button>
          </Row>
        );
      },
    },
  ];
  const onSubmit = async (values) => {
    setModalLoading(true);
    let response =
      labelId !== null
        ? await updateLabel(labelId, values)
        : createLabel(values);
    if (response.ok) {
      message.success(
        labelId !== null ? 'Etiqueta actualizada' : 'Etiqueta agregada.'
      );
      update();
      setLabelId(null);
      setModal(false);
      formRef.resetFields();
    } else {
      message.error(
        labelId !== null
          ? 'Error actualizando etiqueta '
          : 'Error Agregando etiqueta '
      );
    }
    setModalLoading(false);
  };
  return (
    <Card className="slideInLeft">
      <Modal
        visible={modal}
        title={labelId !== null ? 'Detalles de la etiqueta' : 'Nueva etiqueta'}
        okText={labelId !== null ? 'Actualizar' : 'Agregar'}
        centered
        cancelText="Cancelar"
        width={1000}
        okButtonProps={{ loading: modalLoading }}
        onOk={() => formRef.submit()}
        onCancel={() => {
          setModal(false);
          formRef.resetFields();
          setLabelId(null);
        }}
      >
        <LabelForm
          formRef={formRef}
          onSubmit={onSubmit}
          layout="vertical"
          style={{ width: '100%' }}
        />
      </Modal>
      <Alert
        message="Precaución"
        description="Este aparatado permite la modificación de las plantillas que se mandan por correo electronico, como lo son las alertas y los mensajes del sistema de MLC. Tenga cuidado al momento de modificar una etiqueta"
        type="warning"
        showIcon
        closable
      />
      <Divider orientation="left">Etiquetas</Divider>
      <Row style={{ marginBottom: 20 }}>
        <Button type="primary" onClick={() => setModal(true)}>
          Agregar
        </Button>
      </Row>
      <Row>
        <Col span={24}>
          <Filter
            fields={[
              {
                title: 'Slug',
                dataIndex: 'slug',
              },
              {
                title: 'Lenguaje',
                dataIndex: 'language',
              },
              {
                title: 'País',
                dataIndex: 'country',
              },
              {
                title: 'Sección',
                dataIndex: 'section',
              },
            ]}
            handler={query}
          />
        </Col>
      </Row>
      <Table
        loading={loading}
        dataSource={labels.data}
        rowKey={(row) => row._id}
        columns={columns}
        locale={{ emptyText: 'Sin etiquetas' }}
        pagination={{
          total: labels.total,
          current: labels.skip / 10 + 1,
          onChange: (e) => query([], (e - 1) * 10),
        }}
      />
    </Card>
  );
};

export default Etiquetas;
