import React from 'react';

import { BarChart, PieChart, TrendingUp } from 'react-feather';
export const FeatureItems = [
  {
    icon: <BarChart size={64} strokeWidth={0.5} />,
    title: 'Contabilidad',
    subtitle: 'Visibilidad financiera',
    description: `Nuestro módulo de contabilidad le permite a los usuarios
      subir estados de cuenta, solicitar facturas y generar reportes`,
  },
  {
    icon: <TrendingUp size={64} strokeWidth={0.5} />,
    title: 'Nóminas',
    subtitle: 'Adminitración de empleados',
    description: `El sistema permite gestionar altas y bajas de empleados,
      así como requerir acciones sobre los beneficios de los mismos`,
  },
  {
    icon: <PieChart size={64} strokeWidth={0.5} />,
    title: 'Auditorías',
    subtitle: 'Transparencia en la empresa',
    description: `Genere un reporte financiero a través de nuestro sistema,
      utilizando el mismo para subir toda la información necesaria`,
  },
];
