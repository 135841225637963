import React, { useState } from 'react';
import { Form, Input, Tabs, Col, Row } from 'antd';
import AceEditor from 'react-ace';
import JoditEditor from 'jodit-react';

import 'ace-builds/src-noconflict/mode-html';
import 'ace-builds/src-noconflict/theme-github';
import 'ace-builds/src-noconflict/snippets/html';
import 'ace-builds/src-noconflict/mode-jsx';
import 'ace-builds/src-min-noconflict/ext-searchbox';
import 'ace-builds/src-min-noconflict/ext-language_tools';
import 'ace-builds/webpack-resolver';

const { TabPane } = Tabs;

const LabelForm = ({ formRef, onSubmit, loading, ...props }) => {
  const [tab, setTab] = useState('text');
  return (
    <Form
      form={formRef}
      onFinish={onSubmit}
      labelCol={{ span: 8 }}
      initialValues={{ country: '*', language: '*', text: '' }}
      {...props}
    >
      <Row gutter={[12, 12]}>
        <Col span={12}>
          <Form.Item
            label="Slug"
            name="slug"
            labelAlign="left"
            rules={[{ required: true, message: 'Por favor ingrese el slug!' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Sección"
            name="section"
            labelAlign="left"
            rules={[
              { required: true, message: 'Por favor ingrese la sección!' },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item label="Idioma" name="language" labelAlign="left">
            <Input disabled />
          </Form.Item>
          <Form.Item label="País" name="country" labelAlign="left">
            <Input disabled />
          </Form.Item>
          <Form.Item name="text" labelAlign="left" style={{ display: 'none' }}>
            <Input disabled />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label="Texto" labelAlign="left" shouldUpdate>
            {() => (
              <Tabs activeKey={tab} onChange={(e) => setTab(e)} type="card">
                <TabPane tab="Texto Simple" key="text">
                  {tab === 'text' && (
                    <JoditEditor
                      value={formRef.getFieldValue('text')}
                      onChange={(e) => {
                        formRef.setFieldsValue({ text: e });
                      }}
                    />
                  )}
                </TabPane>
                <TabPane tab="HTML" key="html">
                  {tab === 'html' && (
                    <AceEditor
                      placeholder="Escriba el HTML"
                      mode="html"
                      theme="github"
                      name="blah2"
                      onChange={(e) => {
                        formRef.setFieldsValue({ text: e });
                      }}
                      height="300px"
                      fontSize={14}
                      showPrintMargin={true}
                      showGutter={true}
                      highlightActiveLine={true}
                      value={formRef.getFieldValue('text')}
                      setOptions={{
                        enableBasicAutocompletion: true,
                        enableLiveAutocompletion: true,
                        enableSnippets: true,
                        showLineNumbers: true,
                        tabSize: 2,
                      }}
                    />
                  )}
                </TabPane>
              </Tabs>
            )}
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export default LabelForm;
