import { create } from 'apisauce';
import { store } from '../Redux/store';
import { setQuery } from '../Utils/query';
import { Auth } from '../Redux/reducers/auth';

import { getServer } from '../Utils/url';
import { Error } from '../Redux/reducers/error';

export const token = () => store.getState().auth.token;
// Define the api
const API = create({
  baseURL: getServer(),
});
const monitor = response => {
  if (response.status === 401) {
    store.dispatch(Auth.authClear());
  }
  if (!response.ok) {
    if (response.status !== 401) {
      store.dispatch(Error.errorSet(response));
    }
  }
};

API.addMonitor(monitor);

export const login = async (email, password) =>
  await API.post('/auth', { email, password, strategy: 'local' });
/**Forgot */
export async function requestLink(body) {
  return await API.post(`/forgot`, body, {
    headers: { 'x-api-key': process.env.REACT_APP_API_TOKEN },
  });
}
export async function validToken(value) {
  return await API.get(
    `/forgot/${value}`,
    {},
    { headers: { 'x-api-key': process.env.REACT_APP_API_TOKEN } }
  );
}
export async function changePassword(id, body) {
  return await API.patch(`/forgot/${id}`, body, {
    headers: { 'x-api-key': process.env.REACT_APP_API_TOKEN },
  });
}
//Validate user
export async function validateUser(body) {
  return await API.post(`/verify`, body, {
    headers: { 'x-api-key': process.env.REACT_APP_API_TOKEN },
  });
}
//Users
export const getUsers = async (queries = [], skip = 0, limit = 10) =>
  await API.get(
    `/users?${setQuery(queries)}&$skip=${skip}&$limit=${limit}`,
    {},
    {
      headers: {
        Authorization: `bearer ${token()}`,
      },
    }
  );
export const createUser = async body =>
  await API.post(`/users`, body, {
    headers: {
      Authorization: `bearer ${token()}`,
    },
  });
export const updateUser = async (id, body) =>
  await API.patch(`/users/${id}`, body, {
    headers: {
      Authorization: `bearer ${token()}`,
    },
  });

//Roles
export const getRoles = async (queries = [], skip = 0, limit = 10) =>
  await API.get(
    `/roles?${setQuery(queries)}&$skip=${skip}&$limit=${limit}`,
    {},
    {
      headers: {
        Authorization: `bearer ${token()}`,
      },
    }
  );

//File types
export const getFileTypes = async (queries = [], skip = 0, limit = 10) =>
  await API.get(
    `/file-types?${setQuery(queries)}&$skip=${skip}&$limit=${limit}`,
    {},
    {
      headers: {
        Authorization: `bearer ${token()}`,
      },
    }
  );
export const createFileTypes = async body =>
  await API.post(`/file-types`, body, {
    headers: {
      Authorization: `bearer ${token()}`,
    },
  });

//Departments
export const getDepartments = async (queries = [], skip = 0, limit = 10) =>
  await API.get(
    `/departments?${setQuery(queries)}&$skip=${skip}&$limit=${limit}`,
    {},
    {
      headers: {
        Authorization: `bearer ${token()}`,
      },
    }
  );
export const getMemberDepartments = async user_id =>
  await API.get(
    `/departments?members.user_id=${user_id}`,
    {},
    {
      headers: {
        Authorization: `bearer ${token()}`,
      },
    }
  );
export const addMemberToDepartment = async (id, user_id) =>
  await API.patch(
    `/departments/${id}`,
    {
      $push: {
        members: [
          {
            user_id,
          },
        ],
      },
    },
    {
      headers: {
        Authorization: `bearer ${token()}`,
      },
    }
  );
export const removeMemberInDepartment = async (id, user_id) =>
  await API.patch(
    `/departments/${id}`,
    {
      $pull: {
        members: {
          _id: user_id,
        },
      },
    },
    {
      headers: {
        Authorization: `bearer ${token()}`,
      },
    }
  );
export const createDepartments = async body =>
  await API.post(`/departments`, body, {
    headers: {
      Authorization: `bearer ${token()}`,
    },
  });
export const updateDepartments = async (id, body) =>
  await API.patch(`/departments/${id}`, body, {
    headers: {
      Authorization: `bearer ${token()}`,
    },
  });

//Company
export const getCompanies = async (queries = [], skip = 0, limit = 10) =>
  await API.get(
    `/companies?${setQuery(queries)}&$skip=${skip}&$limit=${limit}`,
    {},
    {
      headers: {
        Authorization: `bearer ${token()}`,
      },
    }
  );
export const createCompany = async body =>
  await API.post(`/companies`, body, {
    headers: {
      Authorization: `bearer ${token()}`,
    },
  });
export const updateCompany = async (id, body) =>
  await API.patch(`/companies/${id}`, body, {
    headers: {
      Authorization: `bearer ${token()}`,
    },
  });

//Multimedia
export const getPath = async ({ is_public, ...props }) =>
  await API.post(
    `/utils`,
    {
      action: 'list-directories',
      is_public,
      ...props,
    },
    {
      headers: {
        Authorization: `bearer ${token()}`,
      },
    }
  );
export const getCustomPath = async custom_path =>
  await API.post(
    `/utils`,
    {
      action: 'list-directories',
      custom_path,
    },
    {
      headers: {
        Authorization: `bearer ${token()}`,
      },
    }
  );
export const getPathByUser = async id =>
  await API.post(
    `/utils`,
    {
      action: 'list-directories',
      custom_path: id + '/',
    },
    {
      headers: {
        Authorization: `bearer ${token()}`,
      },
    }
  );
export const createFolder = async (custom_path, body = {}) =>
  await API.post(
    `/utils`,
    {
      action: 'create-folder',
      custom_path,
      ...body,
    },
    {
      headers: {
        Authorization: `bearer ${token()}`,
      },
    }
  );
export const deleteFolder = async path =>
  await API.post(
    `/utils`,
    {
      action: 'remove-folder',
      path,
    },
    {
      headers: {
        Authorization: `bearer ${token()}`,
      },
    }
  );
export const findFiles = async obj =>
  await API.post(
    `/utils`,
    {
      action: 'find-files',
      ...obj,
    },
    {
      headers: {
        Authorization: `bearer ${token()}`,
      },
    }
  );
export const deleteFile = async id =>
  await API.delete(
    `/multimedia/${id}`,
    {},
    {
      headers: {
        Authorization: `bearer ${token()}`,
      },
    }
  );
export const updateFile = async (id, values) =>
  await API.patch(`/multimedia/${id}`, values, {
    headers: {
      Authorization: `bearer ${token()}`,
    },
  });
export const downloadFile = async (path, fileName = 'file') => {
  let response = await fetch(
    `${getServer()}/upload?target=${path}&token=${token()}`,
    { method: 'GET' }
  );
  if (response.ok) {
    let a = document.createElement('a');
    let url = window.URL.createObjectURL(await response.blob());
    //Link styles
    a.style.display = 'none';
    a.href = url;
    a.download = fileName;
    document.body.appendChild(a);
    //Click link
    a.click();
    window.URL.revokeObjectURL(url);
    document.body.removeChild(a);
  }
};

//Assignments

export const getAssignments = async (queries = [], skip = 0, limit = 10) =>
  await API.get(
    `/assignments?${setQuery(queries)}&$skip=${skip}&$limit=${limit}`,
    {},
    {
      headers: {
        Authorization: `bearer ${token()}`,
      },
    }
  );
export const createAssignments = async body =>
  await API.post(`/assignments`, body, {
    headers: {
      Authorization: `bearer ${token()}`,
    },
  });
export const updateAssignments = async (id, body) =>
  await API.patch(`/assignments/${id}`, body, {
    headers: {
      Authorization: `bearer ${token()}`,
    },
  });
export const deleteAssignments = async _id =>
  await API.delete(
    `/assignments/${_id}`,
    {},
    {
      headers: {
        Authorization: `bearer ${token()}`,
      },
    }
  );

//Request

export const getRequests = async (queries = [], skip = 0, limit = 10) =>
  await API.get(
    `/requests-pages?${setQuery(queries)}&$skip=${skip}&$limit=${limit}`,
    {},
    {
      headers: {
        Authorization: `bearer ${token()}`,
      },
    }
  );
export const getRequest = async id =>
  await API.get(
    `/requests-pages/${id}`,
    {},
    {
      headers: {
        Authorization: `bearer ${token()}`,
      },
    }
  );
export const createRequest = async body =>
  await API.post(`/requests`, body, {
    headers: {
      Authorization: `bearer ${token()}`,
    },
  });
export const updateRequest = async (id, body) =>
  await API.patch(`/requests/${id}`, body, {
    headers: {
      Authorization: `bearer ${token()}`,
    },
  });
export const deleteRequest = async _id =>
  await API.delete(
    `/requests/${_id}`,
    {},
    {
      headers: {
        Authorization: `bearer ${token()}`,
      },
    }
  );

//Empleados - staff
export const getStaffs = async (queries = [], skip = 0, limit = 10) =>
  await API.get(
    `/staffs?${setQuery(queries)}&$skip=${skip}&$limit=${limit}`,
    {},
    {
      headers: {
        Authorization: `bearer ${token()}`,
      },
    }
  );
export const getStaff = async id =>
  await API.get(
    `/staffs/${id}`,
    {},
    {
      headers: {
        Authorization: `bearer ${token()}`,
      },
    }
  );
export const createStaff = async body =>
  await API.post(`/staffs`, body, {
    headers: {
      Authorization: `bearer ${token()}`,
    },
  });
export const updateStaff = async (id, body) =>
  await API.patch(`/staffs/${id}`, body, {
    headers: {
      Authorization: `bearer ${token()}`,
    },
  });
export const deleteStaff = async _id =>
  await API.delete(
    `/staffs/${_id}`,
    {},
    {
      headers: {
        Authorization: `bearer ${token()}`,
      },
    }
  );

//Labels

export const getLabels = async (queries = [], skip = 0, limit = 10) =>
  await API.get(
    `/labels-pages?${setQuery(queries)}&$skip=${skip}&$limit=${limit}`,
    {},
    {
      headers: {
        Authorization: `bearer ${token()}`,
      },
    }
  );
export const createLabel = async body =>
  await API.post(`/labels-pages`, body, {
    headers: {
      Authorization: `bearer ${token()}`,
    },
  });
export const updateLabel = async (id, body) =>
  await API.patch(`/labels-pages/${id}`, body, {
    headers: {
      Authorization: `bearer ${token()}`,
    },
  });
export const deleteLabel = async _id =>
  await API.delete(
    `/labels-pages/${_id}`,
    {},
    {
      headers: {
        Authorization: `bearer ${token()}`,
      },
    }
  );

//Notification

export const getNotification = async () =>
  await API.get(
    `/notifications`,
    {},
    {
      headers: {
        Authorization: `bearer ${token()}`,
      },
    }
  );

export const updateNotification = async (id, body, queries = []) =>
  await API.patch(`/requests/${id}?${setQuery(queries)}`, body, {
    headers: {
      Authorization: `bearer ${token()}`,
    },
  });

//Countries
export const getCountries = async (queries = [], skip = 0, limit = 300) =>
  await API.get(
    `/countries?${setQuery(queries)}&$skip=${skip}&$limit=${limit}`,
    {},
    {
      headers: {
        Authorization: `bearer ${token()}`,
      },
    }
  );
//CSV
export async function importCSV(queries = []) {
  return API.get(
    `import-csv?${setQuery(queries)}`,
    {},
    {
      headers: {
        Authorization: `bearer ${token()}`,
      },
    }
  );
}

//Import logs
export const getImportLogs = async (queries = [], skip = 0, limit = 10) =>
  await API.get(
    `/import-logs?${setQuery(queries)}&$skip=${skip}&$limit=${limit}`,
    {},
    {
      headers: {
        Authorization: `bearer ${token()}`,
      },
    }
  );
