import React, { useState } from 'react';
import { Button } from 'antd';
import { downloadFile } from '../../Services/API';

const LoadingButton = ({ path, fileName }) => {
  const [_loading, setLoading] = useState(false);
  async function fetchFile() {
    setLoading(true);
    await downloadFile(path, fileName);
    setLoading(false);
  }
  return (
    <Button
      type="link"
      key="list-loadmore-edit"
      disabled={_loading}
      onClick={fetchFile}
    >
      {_loading ? 'Descargando...' : 'Descargar'}
    </Button>
  );
};

export default LoadingButton;
