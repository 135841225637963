import React from 'react';
import { Form, Input } from 'antd';
import IconSelect from '../Input/IconSelect';

const DepartmentForm = ({ formRef, onSubmit, loading, ...props }) => {
  return (
    <Form
      form={formRef}
      onFinish={onSubmit}
      labelCol={{ span: 8 }}
      wrapperCol={{ span: 16 }}
      {...props}
    >
      <Form.Item
        label="Nombre"
        name="name"
        labelAlign="left"
        rules={[{ required: true, message: 'Por favor ingrese el nombre!' }]}
      >
        <Input />
      </Form.Item>
      <Form.Item label="Icono" name="icon" labelAlign="left">
        <IconSelect style={{ width: '100%' }} showSearch notFoundContent="Sin resultados"/>
      </Form.Item>
    </Form>
  );
};

export default DepartmentForm;
