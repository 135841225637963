import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { withRouter } from 'react-router-dom';

import HomeScreen from '../Screens/Home';
import NewUser from '../Screens/NewUser';
import UserList from '../Screens/UserList';
import FileTypes from '../Screens/FileTypes';
import Departments from '../Screens/Departments';
import Files from '../Screens/Files';
import Relaciones from '../Screens/Relaciones';
import ModuleHome from '../Screens/ModuleHome';
import RequestUser from '../Screens/RequestUser';
import CapturaInformacion from '../Screens/CapturaInformacion';
import RequestView from '../Screens/RequestView';
import NotFound from '../Screens/NotFound';
import Empleados from '../Screens/Empleados';
import Etiquetas from '../Screens/Etiquetas';
import RoleRoute from './Routes/RoleRoute';
import ImportScreen from '../Screens/Import/Index';
import ImportLogs from '../Screens/ImportLogs';

const SubRouter = ({ match: { url }, locations }) => {
  return (
    <Switch>
      <Route
        path={`${url}`}
        component={HomeScreen}
        location={locations}
        exact
      />
      <RoleRoute
        path={`${url}/administracion/usuarios/nuevo`}
        component={NewUser}
        location={locations}
        roles={['admin', 'employee', 'leader']}
        exact
      />
      <RoleRoute
        path={`${url}/administracion/usuarios/`}
        component={UserList}
        location={locations}
        roles={['admin', 'employee', 'leader']}
        exact
      />
      <RoleRoute
        path={`${url}/administracion/filetypes/`}
        component={FileTypes}
        location={locations}
        roles={['admin', 'leader']}
        exact
      />
      <RoleRoute
        path={`${url}/administracion/departamentos/`}
        component={Departments}
        location={locations}
        roles={['admin']}
        exact
      />
      <RoleRoute
        path={`${url}/administracion/relaciones/`}
        component={Relaciones}
        location={locations}
        roles={['admin', 'leader']}
        exact
      />
      <RoleRoute
        path={`${url}/administracion/import/`}
        component={ImportScreen}
        location={locations}
        roles={['admin', 'leader']}
        exact
      />
      <RoleRoute
        path={`${url}/administracion/import-logs/`}
        component={ImportLogs}
        location={locations}
        roles={['admin', 'leader']}
      />
      <RoleRoute
        path={`${url}/administracion/etiquetas/`}
        component={Etiquetas}
        roles={['admin']}
        location={locations}
        exact
      />
      <RoleRoute
        path={`${url}/modulo/*/solicitud`}
        component={ModuleHome}
        location={locations}
        roles={['employee', 'leader']}
      />
      <RoleRoute
        path={`${url}/modulo/*/captura`}
        component={CapturaInformacion}
        location={locations}
        roles={['client']}
      />
      <RoleRoute
        path={`${url}/modulo/*/request/*`}
        component={RequestUser}
        location={locations}
        roles={['employee', 'leader', 'client']}
      />
      <RoleRoute
        path={`${url}/modulo/*/empleados`}
        component={Empleados}
        location={locations}
        roles={['employee', 'leader', 'client']}
        exact
      />
      <RoleRoute
        path={`${url}/request/*`}
        component={RequestView}
        location={locations}
        roles={['employee', 'leader', 'client']}
      />

      <Route
        path={`${url}/files/`}
        component={Files}
        location={locations}
        exact
      />

      <Route component={NotFound} />
    </Switch>
  );
};

export default withRouter(SubRouter);
