import React, { useState } from 'react';

import { Skeleton, Table, Button, Select, Row } from 'antd';
import { Trash } from 'react-feather';

//Hooks
import { useFetchUserPermission } from '../../Hooks/Permission.hook';

const Permisos = ({ user, ...props }) => {
  const [
    assign,
    all,
    loading,
    softLoading,
    add,
    remove,
  ] = useFetchUserPermission(user._id);
  const [selected, setSelect] = useState(null);
  const columns = [
    { title: 'Módulo', dataIndex: 'name', width: 400 },
    {
      title: '',
      dataIndex: '_id',
      render(e, row) {
        let data = row.members.filter((m) => m.user_id === user._id)[0] || {};
        return (
          <Button
            danger
            type="primary"
            icon={<Trash size={14} className="anticon" />}
            onClick={() => {
              remove(e, data._id);
            }}
          />
        );
      },
    },
  ];
  if (loading) {
    return <Skeleton active />;
  }
  return (
    <div style={{ minHeight: 300, padding: 10 }}>
      <Row justify="space-between" style={{ marginBottom: 10 }}>
        <Select
          style={{ width: 200 }}
          placeholder="Seleccione un módulo"
          value={selected}
          notFoundContent={<span>Sin módulos disponibles</span>}
          onChange={(e) => setSelect(e)}
        >
          {all.map((module) => (
            <Select.Option value={module._id} key={module._id}>
              {module.name}
            </Select.Option>
          ))}
        </Select>
        <Button
          type="primary"
          disabled={selected === null}
          loading={softLoading}
          onClick={() => {
            add(selected);
            setSelect(null);
          }}
        >
          Agregar
        </Button>
      </Row>
      <Table
        columns={columns}
        dataSource={assign}
        rowKey={(row) => row._id}
        pagination={false}
        locale={{ emptyText: 'Sin permisos' }}
      />
    </div>
  );
};

export default Permisos;
