import React from 'react';
import { Result, Button, Collapse, Card } from 'antd';

const { Panel } = Collapse;

const ErrorFallback = ({ error, componentStack, resetErrorBoundary }) => {
  return (
    <Card role="alert" className="slideInLeft ">
      <Result
        status="error"
        title="Hubo un error inesperado"
        subTitle="Pueda que no tenga permisos para ver esta sección, si el error persiste consulte al administrador."
        extra={[
          <Button type="primary" onClick={resetErrorBoundary} key="1">
            Intentar de nuevo
          </Button>,
        ]}
      >
        <div className="desc">
          <h3 style={{ textAlign: 'left' }}>Error Stack</h3>
          <Collapse>
            <Panel header={error.message} key="2">
              <pre>{componentStack}</pre>
            </Panel>
          </Collapse>
        </div>
      </Result>
    </Card>
  );
};

export default ErrorFallback;
