import React from 'react';
import {
  Home,
  User,
  UserPlus,
  File,
  Database,
  Grid,
  FilePlus,
  Upload,
  Tag,
  AlertOctagon,
} from 'react-feather';
import IconRender from '../Components/IconRender';

export const MenuRoutes = [
  {
    title: 'Inicio',
    key: '/',
    icon: <Home size={14} strokeWidth={1} className="anticon" />,
    permission: ['admin', 'leader', 'client', 'employee'],
  },
  {
    title: 'Administración',
    key: '/administracion',
    hasChildren: true,
    icon: <User size={14} strokeWidth={1} className="anticon" />,
    permission: ['admin', 'leader', 'employee'],
    children: [
      {
        title: 'Nuevo Usuario',
        key: '/usuarios/nuevo',
        icon: <UserPlus size={14} strokeWidth={1} className="anticon" />,
        permission: ['admin', 'leader'],
      },

      {
        title: 'Lista de Usuarios',
        key: '/usuarios/',
        icon: <User size={14} strokeWidth={1} className="anticon" />,
        permission: ['admin', 'leader', 'employee'],
      },
      {
        title: 'Tipos de archivo',
        key: '/filetypes/',
        icon: <File size={14} strokeWidth={1} className="anticon" />,
        permission: ['admin', 'leader'],
      },
      {
        title: 'Relaciones',
        key: '/relaciones/',
        icon: <Grid size={14} strokeWidth={1} className="anticon" />,
        permission: ['admin', 'leader'],
      },
      {
        title: 'Gestión de módulos',
        key: '/departamentos/',
        icon: <Database size={14} strokeWidth={1} className="anticon" />,
        permission: ['admin'],
      },
      {
        title: 'Importación de datos',
        key: '/import/',
        icon: <Upload size={14} strokeWidth={1} className="anticon" />,
        permission: ['admin', 'leader'],
      },
      {
        title: 'Registro de importación',
        key: '/import-logs/',
        icon: <AlertOctagon size={14} strokeWidth={1} className="anticon" />,
        permission: ['admin', 'leader'],
      },
      {
        title: 'Etiquetas',
        key: '/etiquetas/',
        icon: <Tag size={14} strokeWidth={1} className="anticon" />,
        permission: ['admin'],
      },
    ],
  },
];

export const moduleRoutes = (module) => {
  return {
    title: module.name,
    key: `/modulo/${module.name}`,
    permission: ['admin', 'leader', 'client', 'employee'],
    icon: (
      <IconRender
        iconName={module.icon}
        size={14}
        strokeWidth={1}
        className="anticon"
      />
    ),
    hasChildren: true,
    children: [
      {
        title: 'Solicitud de Archivos',
        key: '/solicitud',
        icon: <FilePlus size={14} strokeWidth={1} className="anticon" />,
        permission: ['admin', 'leader', 'employee'],
      },
      {
        title: 'Captura de información',
        key: '/captura',
        icon: <Upload size={14} strokeWidth={1} className="anticon" />,
        permission: ['client'],
      },
    ],
  };
};
