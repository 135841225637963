export function setQuery(querys = []) {
  let result = [];
  let i = 0;

  for (let item of querys) {
    if (typeof item.operator !== 'undefined') {
      switch (item.operator) {
        case '$like':
        case '$iLike':
          result.push(`${item.field || 'A'}[${item.operator}]=${item.value}%`);
          break;
        case '$or':
          result.push(`$or[${i}][${item.field || 'A'}]=${item.value}`);
          i++;
          break;
        case '$or_opt':
          result.push(`$or[${i}][${item.field || 'A'}][${item.suboperator}]=${item.value}`);
          i++;
          break;
        case '=':
        case '$equals':
          result.push(`${item.field || 'A'}=${item.value}`);
          break;
        case '$select':
          result.push(`${item.field}[]=${item.value}`);
          break;
        case '$sort':
          result.push(`${item.operator}[${item.field}]=${item.value}`);
          break;
        default:
          result.push(`${item.field || 'A'}[${item.operator}]=${item.value}`);
      }
    } else {
      result.push(`${item.field || 'A'}=${item.value}`);
    }
  }

  return result.join('&');
}
