import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

import auth from './auth';
import app from './app';
import roles from './roles';
import departments from './departments';
import error from './error';

const reducer = (history) =>
  combineReducers({
    router: connectRouter(history),
    auth,
    app,
    roles,
    departments,
    error
  });

export default reducer;
