import { useEffect, useState } from 'react';
import { getImportLogs } from '../Services/API';
//Labels
export function useFetchImportLogs(queries = [], skip = 0, limit = 10) {
  const [loading, setLoading] = useState(true);
  const [params, setParams] = useState({ queries, skip, limit });
  const [error, setError] = useState(null);
  const [data, setData] = useState({ total: 0, data: [], skip: 0 });
  useEffect(() => {
    setLoading(true);
    getImportLogs(params.queries, params.skip, params.limit).then(
      (response) => {
        if (response.ok) {
          setData(response.data);
        } else {
          setError(response.data);
        }
        setLoading(false);
      }
    );
  }, [params, setData, setError, setLoading]);
  function onChangeParams(q = [], s = 0, l = 10) {
    setParams({ queries: q, skip: s, limit: l });
  }
  async function update() {
    setLoading(true);
    let response = await getImportLogs(
      params.queries,
      params.skip,
      params.limit
    );
    if (response.ok) {
      setData(response.data);
    } else {
      setError(response.data);
    }
    setLoading(false);
  }
  return [data, loading, error, onChangeParams, update];
}
