import React from 'react';
import PropTypes from 'prop-types';
import { PricingIcon } from './Icons';

const FeaturesTable = ({ title, subtitle, description, icon }) => (
  <div className="bg-white text-center px-3 py-5" style={{ margin: 1 }}>
    <PricingIcon className="mb-5 text-primary">{icon}</PricingIcon>

    <div className="mb-5">
      <h5 className="mb-0">{title}</h5>
      <small className="mb-0 text-muted">{subtitle}</small>
    </div>

    <p className="mb-5">{description}</p>
  </div>
);

FeaturesTable.prototypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  description: PropTypes.element.isRequired,
};

export default FeaturesTable;
