//Core components
import React, { useRef } from 'react';
import { Card, Col, Row, Button } from 'antd';
//Redux
import { connect } from 'react-redux';
import { push } from 'connected-react-router';

import FeaturesTable from '../../Components/FeaturesTable';
import { FeatureItems } from '../../Share/DataStruct';

const LandingScreen = ({ navPush }) => {
  const annual = useRef(false);
  return (
    <Row
      type="flex"
      align="middle"
      justify="center"
      className="px-3 bg-white mh-page fadeIn "
      style={{ minHeight: '100vh' }}
    >
      <div style={{ maxWidth: 800, zIndex: '2', width: '100%' }}>
        <div className="text-center my-5">
          <span className="brand">
            <img src="/images/logo.svg" width="24px" alt="MLC" />
            <strong className="mx-1 text-black">MLC</strong>
          </span>
          <h2 className="font-weight-bold" style={{ display: 'block' }}>
            Portal Administrativo.
          </h2>
          <h5>
            Sistema de acceso para gestión de contabilidad, nóminas y auditorias
            de MLC
          </h5>
        </div>

        <Card
          className="shadow-sm rounded overflow-hidden"
          bordered={false}
          bodyStyle={{ padding: 0 }}
        >
          <Row type="flex" justify="space-around" gutter={0}>
            {FeatureItems.map((item, index) => (
              <Col
                className={` ${FeatureItems.length - 1 !== index ? '' : ''}`}
                key={index}
                xl={6}
                md={12}
                sm={24}
                xs={24}
              >
                <FeaturesTable
                  title={item.title}
                  subtitle={item.subtitle}
                  description={item.description}
                  price={!annual.current ? item.price : item.price * 12 * 0.9}
                  features={item.features}
                  icon={item.icon}
                />
              </Col>
            ))}
          </Row>
        </Card>
        <div className="text-center my-5">
          <Button type="primary" onClick={() => navPush('/login')}>
            Entrar al sistema
          </Button>
          <br />
          <br />
          <small className="mb-0 text-muted">
            Actualmente el sistema se encuentra en modo de prueba.
            <br />
            Favor de enviar un correo a admin@zontle.tech
          </small>
        </div>
        <div className="mt-auto"></div>
      </div>
    </Row>
  );
};

export default connect(null, { navPush: push })(LandingScreen);
