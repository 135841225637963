import React from 'react';

import { Form, Input, Button, Select } from 'antd';

//Hooks
import { useFetchRoles } from '../../Hooks/Roles.hook';
import { useAuth } from '../../Hooks/Auth.hook';

const UserForm = ({
  formRef,
  onSubmit,
  loading,
  edit = false,
  rol = 'user',
  ...props
}) => {
  const [auth] = useAuth();
  const [roles] = useFetchRoles([
    { field: '$select[]', value: '_id' },
    { field: '$select[]', value: 'group' },
    { field: '$select[]', value: 'name' },
  ]);

  const filterRoles = () => {
    let rol = auth.role.rol;
    switch (rol) {
      case 'admin':
        return roles.filter((r) => r.group !== 'open-endpoints');
      case 'leader':
        return roles.filter(
          (r) =>
            r.group !== 'admin' &&
            r.group !== 'leader' &&
            r.group !== 'open-endpoints'
        );
      default:
        return roles.filter(
          (r) =>
            r.group !== 'admin' &&
            r.group !== 'leader' &&
            r.group !== 'employee' &&
            r.group !== 'open-endpoints'
        );
    }
  };
  return (
    <Form
      name="user"
      form={formRef}
      onFinish={onSubmit}
      labelCol={{ span: 8 }}
      wrapperCol={{ span: 16 }}
      initialValues={{ status: 0 }}
      {...props}
    >
      <Form.Item
        label="Nombre completo"
        name="full_name"
        rules={[{ required: true, message: 'Por favor ingrese el nombre!' }]}
        labelAlign="left"
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="Tipo de usuario"
        name="rol_id"
        rules={[
          { required: true, message: 'Por favor ingrese el tipo de usuario!' },
        ]}
        labelAlign="left"
      >
        <Select style={{ width: '100%' }}>
          {filterRoles().map((row) => (
            <Select.Option value={row._id} key={row._id}>
              {row.name}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item
        label="Email"
        name="email"
        rules={[{ required: true, message: 'Por favor ingrese el email!' }]}
        labelAlign="left"
      >
        <Input />
      </Form.Item>

      <Form.Item
        label="Contraseña"
        name="password"
        rules={[
          { required: !edit, message: 'Por favor ingrese la contraseña!' },
        ]}
        labelAlign="left"
      >
        <Input.Password />
      </Form.Item>
      <Form.Item
        label="Repite la Contraseña"
        name="rpassword"
        labelAlign="left"
        dependencies={['password']}
        hasFeedback
        rules={[
          {
            required: !edit,
            message: 'Por favor ingrese la contraseña!',
          },
          ({ getFieldValue }) => ({
            validator(rule, value) {
              if (!value || getFieldValue('password') === value) {
                return Promise.resolve();
              }
              return Promise.reject('La contraseña no coincide!');
            },
          }),
        ]}
      >
        <Input.Password />
      </Form.Item>
      <Form.Item label="Estatus" name="status" labelAlign="left">
        <Select style={{ width: '100%' }}>
          <Select.Option value={1}>Activo</Select.Option>
          <Select.Option value={0}>Inactivo</Select.Option>
        </Select>
      </Form.Item>
      {!edit && (
        <Form.Item className="empty-label" label=" " labelAlign="left">
          <Button
            type="primary"
            htmlType="submit"
            size="large"
            style={{ width: 200 }}
            loading={loading}
          >
            Guardar
          </Button>
        </Form.Item>
      )}
    </Form>
  );
};

export default UserForm;
