import React, { useState, useEffect, useCallback } from 'react';
import {
  Card,
  Skeleton,
  Breadcrumb,
  Button,
  Descriptions,
  List,
  Divider,
  Collapse,
  Row,
  Modal,
  message,
  Form,
} from 'antd';
import { Trash, Clock, ArrowLeft } from 'react-feather';
import dayjs from 'dayjs';
import ErrorMessage from '../../Components/Error/ErrorMessage';
import FileIcon from '../../Components/Files/FileIcon';
import ReminderForm from '../../Components/Form/Reminder.form';
import LoadingButton from '../../Components/Files/LoadingButton';
import PreviewButton from '../../Components/Files/PreviewButton';
import FileSelection from '../../Components/Captura/FileSelection';
//Hooks
import { useNavigation } from '../../Hooks/Nav.hook';
import { useAuth } from '../../Hooks/Auth.hook';

//Utils
import { getRequest, updateRequest } from '../../Services/API';
import { getFileType } from '../../Utils/parse';

const { Panel } = Collapse;

const _getRequestId = (path) => path.replace('/dashboard/request/', '');

const RequestView = () => {
  const [
    {
      user: { _id },
      role: { rol },
    },
  ] = useAuth();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({});
  const [modal, setModal] = useState(false);
  const [modalReminder, setModalReminder] = useState(false);
  const [fileBody, setFileBody] = useState({ files: [], description: '' });
  const [modalLoading, setModalLoading] = useState(false);
  const [modalLoadingReminder, setModalReminderLoading] = useState(false);
  const [error, setError] = useState(null);
  const [{ pathname }, , pop] = useNavigation();
  const [reminderRef] = Form.useForm();

  const get = useCallback(
    async (id) => {
      let response = await getRequest(id);
      if (response.ok) {
        setData(response.data);
      } else {
        setError(response.originalError);
      }
      setLoading(false);
    },
    [setData, setError]
  );
  useEffect(() => {
    let id = _getRequestId(pathname);
    if (id && id.indexOf('dashboard') === -1) {
      get(id);
    }
  }, [pathname, get]);
  const _updateHistory = async () => {
    setModalLoading(true);
    let response = await updateRequest(data._id, {
      $push: {
        history: [
          {
            attachments: fileBody.files.map((e) => e._id),
            description:
              fileBody.description !== '' ? fileBody.description : undefined,
            view: 0,
          },
        ],
      },
    });
    if (response.ok) {
      message.success(
        'Se han registrado los archivos correctamente, actualizando...'
      );
      setModal(false);
      setFileBody({ files: [], description: '' });
      get(data._id);
    } else {
      message.error('Ha ocurrido un error: ' + response.data.message);
    }
    setModalLoading(false);
  };
  const _updateReminder = async (values) => {
    setModalReminderLoading(true);
    let response = await updateRequest(data._id, {
      reminders: {
        ...values,
        period_time: values.period_time
          ? values.period_time.format('hh:mm a')
          : undefined,
      },
    });
    if (response.ok) {
      message.success('Se ha guardado el recordatorio, actualizando...');
      setModalReminder(false);
      get(data._id);
    } else {
      message.error('Ha ocurrido un error: ' + response.data.message);
    }
    setModalReminderLoading(false);
  };
  const _removeRequest = (id, key) => {
    Modal.confirm({
      title: '¿Quieres eliminar este elemento?',
      cancelText: 'No',
      okText: 'Si',
      async onOk() {
        let response = await updateRequest(data._id, {
          $pull: {
            history: { _id: id },
          },
        });
        if (response.ok) {
          message.success('Elemento eliminado, actualizando...');
          if (key === 0) {
            pop();
          } else {
            get(data._id);
          }
        } else {
          message.error('Hubo un error eliminando el elemento');
        }
      },
    });
  };

  if (loading) {
    return (
      <Card className="slideInLeft">
        <Skeleton active />
      </Card>
    );
  }
  if (error || !data._id) {
    return <ErrorMessage error={error} />;
  }
  return (
    <Card className="slideInLeft">
      <Breadcrumb>
        <Breadcrumb.Item>
          <Button type="link" onClick={() => pop()}>
            <ArrowLeft className="anticon" size={15} strokeWidth={2} />
            Regresar
          </Button>
        </Breadcrumb.Item>
      </Breadcrumb>
      <Modal
        title="Agregar archivos"
        visible={modal}
        okText="Agregar"
        centered
        okButtonProps={{ loading: modalLoading }}
        cancelText="Cancelar"
        onOk={_updateHistory}
        onCancel={() => {
          setModal(false);
          setFileBody({ files: [], description: '' });
        }}
      >
        <FileSelection body={fileBody} handler={setFileBody} />
      </Modal>
      <Modal
        title="Recordatorio"
        visible={modalReminder}
        okText="Guardar"
        centered
        okButtonProps={{ loading: modalLoadingReminder }}
        cancelText="Cancelar"
        onOk={() => reminderRef.submit()}
        onCancel={() => {
          setModalReminder(false);
          reminderRef.resetFields();
        }}
      >
        <ReminderForm formRef={reminderRef} onSubmit={_updateReminder} />
      </Modal>
      <div>
        <Descriptions title="Detalles generales" column={2}>
          <Descriptions.Item label="Módulo" style={{ fontWeight: 'bold' }}>
            {data.department.name}
          </Descriptions.Item>
          <Descriptions.Item
            label="Tipo de archivo"
            style={{ fontWeight: 'bold' }}
          >
            {data.type.name}
          </Descriptions.Item>
        </Descriptions>
        <Divider orientation="left">Archivos</Divider>
        <Row justify="space-between" style={{ marginBottom: 10 }}>
          <div>
            {(rol === 'employee' || rol === 'leader') && (
              <Button
                type="dashed"
                onClick={() => {
                  const reminders = data.reminders || { period_time: dayjs() };
                  if (reminders) {
                    if (reminders.init_date) {
                      reminderRef.setFieldsValue({
                        ...reminders,
                        init_date: dayjs(reminders.init_date),
                        period_time: dayjs(reminders.period_time, 'hh:mm a'),
                      });
                    }
                  }
                  setModalReminder(true);
                }}
              >
                <Clock className="anticon" size={15} strokeWidth={2} />
                Recordatorio
              </Button>
            )}
          </div>
          <Button
            type="primary"
            onClick={() => {
              setModal(true);
            }}
          >
            Agregar
          </Button>
        </Row>
        <Collapse accordion>
          {data.history.map((row, i) => (
            <Panel
              showArrow={row.attachments.length > 0}
              header={row.description || 'Sin descripción'}
              key={i}
              className={row.user_id === _id ? 'my-panel' : 'custom-panel'}
              extra={
                row.user_id === _id ? (
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                    }}
                  >
                    <h6 style={{ marginRight: 10, textAlign: 'left' }}>
                      T&uacute;
                    </h6>
                    <small style={{ marginRight: 10 }}>
                      {dayjs(row.date).format('DD/MM/YYYY hh:mm a')}
                    </small>
                    <Button
                      danger
                      type="primary"
                      size="small"
                      onClick={() => _removeRequest(row._id, i)}
                      icon={
                        <Trash className="anticon" size={15} strokeWidth={2} />
                      }
                    />
                  </div>
                ) : (
                  <div
                    style={{ display: 'flex', justifyContent: 'space-between' }}
                  >
                    <h6 style={{ marginRight: 10, textAlign: 'left' }}>
                      {rol === 'client' ? 'Empleado MLC' : 'Cliente'}
                    </h6>
                    <small>
                      {dayjs(row.date).format('DD/MM/YYYY hh:mm a')}
                    </small>
                  </div>
                )
              }
            >
              <List
                itemLayout="horizontal"
                dataSource={row.multimedias}
                locale={{ emptyText: 'Sin Archivos' }}
                loading={loading}
                renderItem={(item) => (
                  <List.Item
                    actions={[
                      <PreviewButton
                        path={item.file_path}
                        fileName={item.name}
                      />,
                      <LoadingButton
                        path={item.file_path}
                        fileName={item.name}
                      />,
                    ]}
                  >
                    <List.Item.Meta
                      avatar={
                        <FileIcon
                          fileType={getFileType(item.name)}
                          style={{ fontSize: 40 }}
                          twoToneColor="#60A50D"
                        />
                      }
                      title={item.name}
                    />
                  </List.Item>
                )}
              />
            </Panel>
          ))}
        </Collapse>
      </div>
    </Card>
  );
};

export default RequestView;
