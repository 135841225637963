import React from 'react';
import { Card, Divider, Form, message } from 'antd';

//Forms
import UserForm from '../../Components/Form/User.form';

//API
import { createUser } from '../../Services/API';


const NewUser = () => {
  const [formRef] = Form.useForm();

  const onCreate = async (values) => {
    let response = await createUser(values);
    if (response.ok) {
      message.success('Usuario Agregado');
      formRef.resetFields();
    } else {
      message.error('Error agregando usuario: ' + response.data.message);
    }
  };
  return (
    <div className="slideInLeft">
      <Card>
        <Divider orientation="left">Registro</Divider>
        <UserForm formRef={formRef} onSubmit={onCreate} />
      </Card>
    </div>
  );
};

export default NewUser;
