import React from 'react';

import { Form, Input, Button } from 'antd';
import { useNavigation } from '../../Hooks/Nav.hook';
const ForgotForm = ({ formRef, onSubmit, loading }) => {
  const [, nav] = useNavigation();
  return (
    <Form
      name="auth"
      form={formRef}
      onFinish={onSubmit}
      layout="vertical"
      labelAlign="left"
    >
      <Form.Item
        name="email"
        rules={[{ required: true, message: 'Por favor ingrese su email!' }]}
      >
        <Input placeholder="Email" />
      </Form.Item>
      <div className="forgot" style={{ cursor: 'pointer', marginBottom: 10 }}>
        <span onClick={() => nav('/login')}>
          ¿Ya tienes cuenta?, inicie sesión aquí
        </span>
      </div>

      <Form.Item style={{ textAlign: 'center' }}>
        <Button
          htmlType="submit"
          size="large"
          style={{ width: 200 }}
          loading={loading}
          type="primary"
        >
          Recuperar contraseña
        </Button>
      </Form.Item>
    </Form>
  );
};

export default ForgotForm;
