import React from 'react';
import { Form, Input, Button } from 'antd';
const ResetPasswordForm = ({ navPush, loading, ...props }) => {
  return (
    <Form name="reset" className="login-form" {...props}>
      <Form.Item
        name="password"
        rules={[
          {
            required: true,
            message: 'Ingrese su contraseña!',
          },
          ({ getFieldValue }) => ({
            validator(rule, value) {
              if (value) {
                if (value.length > 5 && value.length < 30) {
                  return Promise.resolve();
                }
                if (value.length > 30) {
                  return Promise.reject(
                    'La contraseña debe ser menor de 30 caracteres'
                  );
                }
              }
              return Promise.reject(
                'La contraseña debe tener por lo menos 6 caracteres'
              );
            },
          }),
        ]}
      >
        <Input type="password" placeholder="Contraseña" className="sub-input" />
      </Form.Item>
      <Form.Item
        name="rpassword"
        dependencies={['password']}
        hasFeedback
        rules={[
          {
            required: true,
            message: 'Repite tu contraseña!',
          },
          ({ getFieldValue }) => ({
            validator(rule, value) {
              if (!value || getFieldValue('password') === value) {
                return Promise.resolve();
              }
              return Promise.reject('Las contraseñas no coinciden!');
            },
          }),
        ]}
      >
        <Input
          type="password"
          placeholder="Confirmar contraseña"
          className="sub-input"
        />
      </Form.Item>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Form.Item>
          <Button type="primary" htmlType="submit" loading={loading}>
            Cambiar contraseña
          </Button>
        </Form.Item>
        <span className="login-form-forgot" onClick={() => navPush('/')}>
          iniciar sesión
        </span>
      </div>
    </Form>
  );
};
export default ResetPasswordForm;
