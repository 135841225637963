import React from 'react';
import { Table, Button, Modal, message } from 'antd';
import dayjs from 'dayjs';
import { Trash } from 'react-feather';

import { useFetchRequest } from '../../Hooks/Request.hook';
import { useNavigation } from '../../Hooks/Nav.hook';
import { deleteRequest } from '../../Services/API';

const RequestList = ({ type, user_id, department_id }) => {
  const [, nav] = useNavigation();
  const [data, loading, , change, update] = useFetchRequest([
    {
      field: 'type_id',
      value: type,
    },
    {
      field: 'user_id',
      value: user_id,
    },
    { field: 'department_id', value: department_id },
  ]);
  const columns = [
    {
      title: 'Fecha',
      dataIndex: 'created_at',
      render(row) {
        return dayjs(row).format('DD/MM/YYYY hh:ss a');
      },
    },
    {
      title: '',
      dataIndex: '_id',
      render(row) {
        return (
          <div>
            <Button
              type="primary"
              onClick={() => nav(`/dashboard/request/${row}`)}
            >
              Ver
            </Button>
            <Button
              type="primary"
              style={{ marginLeft: 10 }}
              danger
              icon={<Trash size={18} className="anticon" />}
              onClick={() =>
                Modal.confirm({
                  title: '¿Desea eliminar este elemento',
                  okText: 'Si',
                  cancelText: 'No',
                  async onOk() {
                    return await deleteRequest(row).then((response) => {
                      if (response.ok) {
                        message.success('Elemento eliminado, actualizando...');
                        update();
                      } else {
                        message.error(
                          'Error eliminando elemento: ' + response.data.message
                        );
                      }
                    });
                  },
                })
              }
            />
            ,
          </div>
        );
      },
    },
  ];
  return (
    <Table
      dataSource={data.data}
      columns={columns}
      loading={loading}
      locale={{ emptyText: 'Sin peticiones' }}
      rowKey={(row) => row._id}
      pagination={{
        total: data.total,
        current: data.skip / 10 + 1,
        onChange: (e) => change([], (e - 1) * 10),
      }}
    />
  );
};

export default RequestList;
