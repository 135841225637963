import React, { useState } from 'react';
import { Card, Col, Divider, Button, Table, Modal, Form, Row, message } from 'antd';
import { fileTypesColumn } from '../../Share/Columns';
//Hooks
import { useFetchFileTypes } from '../../Hooks/FileTypes.hook';
//Form
import FileTypeForm from '../../Components/Form/FileType.form';
//API
import { createFileTypes } from '../../Services/API';
import Filter from '../../Components/Dashboard/Filter';

//TODO agregar iconos y color
const FileTypes = () => {
  const [files, loading, , change, update] = useFetchFileTypes();
  const [formRef] = Form.useForm();
  const [modal, setModal] = useState(false);

  const onSubmit = async (values) => {
    let response = await createFileTypes(values);
    if (response.ok) {
      formRef.resetFields();
      setModal(false);
      message.success('Se ha agregado el registro');
      update();
    } else {
      message.error(response.data.message);
    }
  };
  return (
    <Card className="slideInLeft">
      <Divider orientation="left">Tipos de archivo</Divider>
      <Modal
        visible={modal}
        title="Nuevo tipo de archivo"
        okText="Agregar"
        centered
        cancelText="Cancelar"
        onOk={() => formRef.submit()}
        onCancel={() => setModal(false)}
      >
        <FileTypeForm formRef={formRef} onSubmit={onSubmit} />
      </Modal>
      <Row>
        <Col flex="1">
          <Filter
            handler={change}
            fields={[
              {
                title: 'Nombre',
                dataIndex: 'name',
              },
              {
                title: 'Descripción',
                dataIndex: 'description',
              },
            ]}
          />
        </Col>
        <Col flex="0">
          <Button
            type="primary"
            style={{ marginBottom: 10 }}
            onClick={() => setModal(true)}
          >
            Agregar
          </Button>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Table
            columns={fileTypesColumn}
            dataSource={files.data}
            rowKey={(row) => row._id}
            loading={loading}
            pagination={{
              total: files.total,
              current: files.skip / 10 + 1,
              onChange: (e) => change([], (e - 1) * 10),
            }}
          />
        </Col>
      </Row>
    </Card>
  );
};

export default FileTypes;
