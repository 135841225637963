import immutable from 'seamless-immutable';
import { createReducer, createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  departmentsRequest: [],
  departmentsSuccess: ['departments'],
  departmentsFailure: ['error'],
  clearDepartment: [],
});
const INITIAL_STATE = immutable({
  loading: false,
  error: false,
  errorMessage: null,
  departments: { data: [] },
  fetched: false
});

const request = (state, action) =>
  state.merge({ loading: true, error: false, errorMessage: null });

const failure = (state, action) => {
  let { error } = action;
  return state.merge({
    loading: false,
    error: true,
    errorMessage: error,
  });
};
const success = (state, action) => {
  let { departments } = action;
  return state.merge({
    loading: false,
    error: false,
    errorMessage: null,
    departments,
    fetched: true
  });
};
const clear = (state, action) => INITIAL_STATE;

const HANDLERS = {
  [Types.DEPARTMENTS_REQUEST]: request,
  [Types.DEPARTMENTS_SUCCESS]: success,
  [Types.DEPARTMENTS_FAILURE]: failure,
  [Types.CLEAR_DEPARTMENT]: clear,
};

export const Departments = Creators;
export const departmentsTypes = Types;
export default createReducer(INITIAL_STATE, HANDLERS);
