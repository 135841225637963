import React from 'react';
import { Card, Descriptions } from 'antd';
import { useAuth } from '../../Hooks/Auth.hook';

const Perfil = () => {
  const [{ user }] = useAuth();
  return (
    <Card className="slideInLeft">
      <Descriptions title="Información personal">
        {user.full_name &&
          <Descriptions.Item label="Nombre completo" style={{ fontWeight: 'bold' }}>
            {user.full_name}
          </Descriptions.Item>
        }
        {user.phone && <Descriptions.Item label="Teléfono" style={{ fontWeight: 'bold' }}>{user.phone}</Descriptions.Item>}
        {user.city && <Descriptions.Item label="Ciudad" style={{ fontWeight: 'bold' }}>{user.city}</Descriptions.Item>}
        {user.state && <Descriptions.Item label="Estado" style={{ fontWeight: 'bold' }}>{user.state}</Descriptions.Item>}
        {user.zip_code &&
          <Descriptions.Item label="Código postal" style={{ fontWeight: 'bold' }}>
            {user.zip_code}
          </Descriptions.Item>
        }
      </Descriptions>
    </Card>
  );
};

export default Perfil;
