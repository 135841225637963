import React from 'react';
import { Form, Input } from 'antd';

const FolderForm = ({ formRef, onSubmit, loading, label, ...props }) => {
  return (
    <Form
      form={formRef}
      onFinish={onSubmit}
      labelCol={{ span: 8 }}
      wrapperCol={{ span: 16 }}
      {...props}
    >
      <Form.Item
        label={label || 'Nombre de la carpeta'}
        name="name"
        labelAlign="left"
      >
        <Input />
      </Form.Item>
    </Form>
  );
};

export default FolderForm;
