import React from 'react';

import { Result, Skeleton, Collapse } from 'antd';
import AssignmentManager from './AssignmentManager';

//Hooks
import { useFetchDepartments } from '../../Hooks/Departments.hook';
import { useFetchAssignmentByClient } from '../../Hooks/Assignment.hook';
import { useFetchRolesM } from '../../Hooks/Roles.hook';

const { Panel } = Collapse;

const Assignment = ({ user }) => {
  const [departments, loading] = useFetchDepartments(
    [
      { field: 'members.user_id', value: user._id },
      { field: '$select[]', value: '_id' },
      { field: '$select[]', value: 'name' },
    ],
    0,
    500
  );
  const [roles, loadingRoles] = useFetchRolesM([
    { field: 'group', value: 'employee', operator: '$or' },
    { field: 'group', value: 'leader', operator: '$or' },
  ]);
  const [assignment, preload, update] = useFetchAssignmentByClient(
    departments.data,
    user._id
  );
  if (loading || preload) {
    return (
      <div className="assignment">
        <Skeleton active />
      </div>
    );
  }
  if (departments.data.length === 0) {
    return (
      <div className="assignment">
        <Result
          status="warning"
          title="Sin módulos asignados"
          subTitle="Debes asignarle un módulo primero"
        />
      </div>
    );
  }
  return (
    <div className="assignment">
      <Collapse>
        {assignment.map((row) => (
          <Panel header={row.name} key={row._id}>
            <AssignmentManager
              roles={roles}
              loadingRoles={loadingRoles}
              assignments={row.assignments}
              handler={update}
              department_id={row._id}
              client_id={user._id}
            />
          </Panel>
        ))}
      </Collapse>
    </div>
  );
};

export default Assignment;
