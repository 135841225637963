import React, { useEffect, useCallback, useState } from 'react';
import { Divider, Card, Table, Button, message, Modal } from 'antd';
//Hooks
import { useGetDepartmentByName } from '../../Hooks/Departments.hook';
import { useAuth } from '../../Hooks/Auth.hook';
import { useNavigation } from '../../Hooks/Nav.hook';
import { createRequest, getAssignments } from '../../Services/API';
import FileSelection from '../../Components/Captura/FileSelection';

const _getModule = (path) =>
  path.replace('/dashboard/modulo/', '').split('/')[0] || false;

const ModuleHome = (props) => {
  const [
    {
      user: { _id },
    },
  ] = useAuth();
  const [{ pathname }] = useNavigation();
  const [modal, setModal] = useState(false);
  const [filetype, setFiletype] = useState(null);
  const [fileBody, setFileBody] = useState({
    files: [],
    description: '',
    reminders: {},
  });
  const [modalLoading, setModalLoading] = useState(false);
  const [department] = useGetDepartmentByName(_getModule(pathname));
  const [users, setUsers] = useState({});
  const [loading, setLoading] = useState(false);
  const [, navigate] = useNavigation();
  const columns = [
    {
      title: 'Cliente',
      dataIndex: 'client_name',
    },
    {
      title: '',
      dataIndex: 'client_id',
      render(id, row) {
        return (
          <Button
            type="primary"
            onClick={() =>
              navigate(
                `/dashboard/modulo/${_getModule(pathname)}/request/${id}`
              )
            }
          >
            Seleccionar
          </Button>
        );
      },
    },
  ];
  const getAssignmentCallback = useCallback(
    async (queries = [], skip = 0, limit = 10) => {
      setLoading(true);
      let response = await getAssignments(queries, skip, limit);
      if (response.ok) {
        setUsers(response.data);
      }
      setLoading(false);
    },
    [setLoading, setUsers]
  );
  const get = useCallback(
    (skip) => {
      if (department._id && _id) {
        getAssignmentCallback(
          [
            {
              field: 'user_id',
              value: _id,
            },
            {
              field: 'department_id',
              value: department._id,
            },
          ],
          skip
        );
      }
    },
    [_id, department._id, getAssignmentCallback]
  );
  useEffect(() => {
    get();
  }, [get]);

  const _createRequest = async () => {
    setModalLoading(true);
    let response = await createRequest({
      type_id: filetype,
      history: [
        {
          attachments: fileBody.files.map((e) => e._id),
          description:
            fileBody.description !== '' ? fileBody.description : undefined,
          view: 0,
        },
      ],
      status: 1,
      client_id: _id,
      department_id: department._id,
    });
    if (response.ok) {
      message.success(
        'Se han registrado los archivos correctamente, actualizando...'
      );
      setModal(false);
      setFileBody({ files: [], description: '' });
      get();
    } else {
      message.error('Ha ocurrido un error: ' + response.data.message);
    }
    setModalLoading(false);
  };
  return (
    <Card className="slideInLeft">
      <Divider orientation="left">Solicitud de archivos</Divider>
      <div>
        El módulo de requerimiento de archivos permite a empleados seleccionar
        clientes registrados en el sistema y crear solicitudes de requerimiento
        de archivos para los mismos.
      </div>
      <Modal
        title="Agregar archivos"
        visible={modal}
        okText="Agregar"
        centered
        okButtonProps={{ loading: modalLoading }}
        cancelText="Cancelar"
        onOk={_createRequest}
        onCancel={() => {
          setModal(false);
          setFiletype(null);
          setFileBody({ files: [], description: '' });
        }}
      >
        {filetype !== null && (
          <FileSelection body={fileBody} handler={setFileBody} />
        )}
      </Modal>
      <div style={{ marginTop: 20 }}>
        <Table
          columns={columns}
          dataSource={users.data}
          rowKey={(e) => e._id}
          loading={loading}
          className="responsive-table"
          locale={{ emptyText: 'Sin registros' }}
          pagination={{
            total: users.total,
            current: users.skip / 10 + 1,
            onChange: (e) =>
              getAssignmentCallback(
                [
                  {
                    field: 'user_id',
                    value: _id,
                  },
                  {
                    field: 'department_id',
                    value: department._id,
                  },
                ],
                (e - 1) * 10
              ),
          }}
        />
      </div>
    </Card>
  );
};

export default ModuleHome;
