import { useState, useCallback, useEffect } from 'react';
import { getAssignments, getUsers } from '../Services/API';

export function useFetchAssignment(queries = [], skip = 0, limit = 10) {
  const [loading, setLoading] = useState(true);
  const [params, setParams] = useState({ queries, skip, limit });
  const [error, setError] = useState(null);
  const [data, setData] = useState({ total: 0, data: [], skip: 0 });
  useEffect(() => {
    setLoading(true);
    getAssignments(params.queries, params.skip, params.limit).then(
      (response) => {
        if (response.ok) {
          setData(response.data);
        } else {
          setError(response.data);
        }
        setLoading(false);
      }
    );
  }, [params, setData, setError, setLoading]);
  function onChangeParams(q = [], s = 0, l = 10) {
    setParams({ queries: q, skip: s, limit: l });
  }
  async function update() {
    setLoading(true);
    let response = await getAssignments(
      params.queries,
      params.skip,
      params.limit
    );
    if (response.ok) {
      setData(response.data);
    } else {
      setError(response.data);
    }
    setLoading(false);
  }
  return [data, loading, error, onChangeParams, update];
}
export function useQueryAssignment() {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [data, setData] = useState({ total: 0, data: [], skip: 0 });
  const query = async function (queries = [], skip = 0, limit = 10) {
    setLoading(true);
    let response = await getAssignments(queries, skip, limit);
    if (response.ok) {
      setData(response.data);
    } else {
      setError(response.data);
    }
    setLoading(false);
  };
  return [data, loading, error, query];
}
export function useFetchAssignmentClient(department_id, client_id) {
  const [loading, setLoading] = useState(true);
  const [params, setParams] = useState({
    department_id,
    client_id,
    skip: 0,
    limit: 10,
  });
  const [error, setError] = useState(null);
  const [data, setData] = useState({ total: 0, data: [], skip: 0 });
  useEffect(() => {
    setLoading(true);
    if (params.client_id && params.department_id) {
      getAssignments(params.queries, params.skip, params.limit).then(
        (response) => {
          if (response.ok) {
            setData(response.data);
          } else {
            setError(response.data);
          }
          setLoading(false);
        }
      );
    }
  }, [params, setData, setError, setLoading]);
  function onChangeParams(d, c, s = 0, l = 10) {
    setParams({ department_id: d, client_id: c, skip: s, limit: l });
  }
  async function update() {
    setLoading(true);
    let response = await getAssignments(
      [
        {
          field: 'client_id',
          value: params.client_id,
        },
        {
          field: 'department_id',
          value: params.department_id,
        },
      ],
      params.skip,
      params.limit
    );
    if (response.ok) {
      setData(response.data);
    } else {
      setError(response.data);
    }
    setLoading(false);
  }
  return [data, loading, error, onChangeParams, update];
}
export const useFetchAssignmentByClient = (modules = [], client_id) => {
  const [assignment, setAssignment] = useState([]);
  const [loading, setLoading] = useState(false);
  const searchAssign = useCallback(async () => {
    if (client_id) {
      setLoading(true);
      let memo = [];
      for (let module of modules) {
        let extra = {};
        let response = await getAssignments([
          {
            field: 'client_id',
            value: client_id,
          },
          {
            field: 'department_id',
            value: module._id,
          },
        ]);
        if (response.ok) {
          let assignmentList = [];
          for (let item of response.data.data) {
            let employee = await (
              await getUsers([
                { field: '_id', value: item.user_id },
                { field: '$select[]', value: '_id' },
                { field: '$select[]', value: 'full_name' },
                { field: '$select[]', value: 'email' },
              ])
            ).data.data[0];
            assignmentList.push({ ...item, employee });
          }
          extra = { assignments: assignmentList };
        }
        memo.push({ ...module, ...extra });
      }
      setLoading(false);
      setAssignment(memo);
    }
  }, [modules, client_id, setLoading]);

  useEffect(() => {
    searchAssign();
  }, [searchAssign]);

  return [assignment, loading, searchAssign];
};

export const useFetchAssignmentModule = (module, user_id) => {
  const [users, setUser] = useState([]);
  const [loading, setLoading] = useState(false);

  const searchUser = useCallback(async () => {
    if (module && user_id) {
      setLoading(true);
      let memo = [];
      let response = await getAssignments(
        [
          {
            field: 'user_id',
            value: user_id,
          },
          {
            field: 'department_id',
            value: module,
          },
        ],
        0,
        500
      );
      if (response.ok) {
        for (let item of response.data.data) {
          let client = await (
            await getUsers([
              { field: '_id', value: item.client_id },
              { field: '$select[]', value: '_id' },
              { field: '$select[]', value: 'full_name' },
              { field: '$select[]', value: 'email' },
              { field: '$select[]', value: 'status' },
            ])
          ).data.data[0];
          memo.push(client);
        }
      }
      setUser(memo);
      setLoading(false);
    }
  }, [setLoading, setUser, module, user_id]);
  useEffect(() => {
    searchUser();
  }, [searchUser]);
  return [users, loading];
};
