import React from 'react';

import { Form, Input, Button } from 'antd';
import { useNavigation } from '../../Hooks/Nav.hook';

const LoginForm = ({ formRef, onSubmit, loading }) => {
  const [, nav] = useNavigation();
  return (
    <Form
      name="auth"
      form={formRef}
      onFinish={onSubmit}
      layout="vertical"
      labelAlign="left"
    >
      <Form.Item
        label="Email"
        name="email"
        rules={[{ required: true, message: 'Por favor ingrese su email!' }]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label="Contraseña"
        name="password"
        rules={[
          { required: true, message: 'Por favor ingrese su contraseña!' },
        ]}
      >
        <Input.Password />
      </Form.Item>
      <div className="forgot">
        <span onClick={() => nav('/forgot')}>¿Olvidaste tu contraseña?</span>
      </div>
      <Form.Item style={{ textAlign: 'center' }}>
        <Button
          type="primary"
          htmlType="submit"
          size="large"
          style={{ width: 200 }}
          loading={loading}
        >
          Entrar
        </Button>
      </Form.Item>
    </Form>
  );
};

export default LoginForm;
