import React, { useState, useEffect, useCallback } from 'react';
import { Row, Card, Result, Button, Skeleton } from 'antd';
import { validateUser } from '../../Services/API';
import { useNavigation } from '../../Hooks/Nav.hook';

const VerifyUser = () => {
  const [valid, setValid] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [
    {
      query: { q },
    },
    nav,
  ] = useNavigation();
  const validate = useCallback(async () => {
    setLoading(true);
    let response = await validateUser({ token: q });
    if (response.ok) {
      setValid(true);
    } else {
      setError(true);
      setErrorMessage(response.data.message);
      setValid(false);
    }
    setLoading(false);
  }, [q, setError, setErrorMessage, setValid, setLoading]);
  useEffect(() => {
    if (q) {
      validate();
    }
  }, [validate, q]);
  const _renderContent = () => {
    if (loading) {
      return <Skeleton active />;
    }
    if (error) {
      return (
        <Result
          status="error"
          title="Hubo un error"
          subTitle={errorMessage}
          extra={[
            <Button type="primary" key="console" onClick={() => nav('/')}>
              Ir a inicio
            </Button>,
          ]}
        />
      );
    }
    if (valid) {
      return (
        <Result
          status="success"
          title="Usuario Activado Exitosamente"
          subTitle="Ahora puede hacer uso del sistema MLC"
          extra={[
            <Button type="primary" key="console" onClick={() => nav('/login')}>
              Iniciar Sesión
            </Button>,
          ]}
        />
      );
    }
    return (
      <Result
        status="warning"
        title="Verifica la URL"
        extra={[
          <Button type="primary" key="console" onClick={() => nav('/')}>
            Ir a inicio
          </Button>,
        ]}
      />
    );
  };
  return (
    <div className="login-screen  fadeIn ">
      <Row
        type="flex"
        align="middle"
        justify="center"
        className="px-3 bg-white mh-page"
      >
        <Card
          className="shadow-sm rounded overflow-hidden wrapper"
          bordered={false}
          bodyStyle={{ padding: 0 }}
        >
          <div className="">
            <div className="text-center my-5">
              <span className="brand">
                <img src="/images/logo.svg" width="24px" alt="MLC" />
                <strong className="mx-1 text-black">MLC</strong>
              </span>
              <h2 className="font-weight-bold" style={{ display: 'block' }}>
                Activación
              </h2>
            </div>
            <div style={{ width: '80%', margin: 'auto' }}>
              {_renderContent()}
            </div>
          </div>
        </Card>
      </Row>
    </div>
  );
};

export default VerifyUser;
