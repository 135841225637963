import React from 'react';
import { Form, Select, TimePicker, InputNumber } from 'antd';
import DatePickerDay from '../Antd/DatePickerDay';
import dayjs from 'dayjs';

const ReminderForm = ({ formRef, onSubmit, loading, ...props }) => {
  return (
    <Form
      form={formRef}
      onFinish={onSubmit}
      labelCol={{ span: 16 }}
      wrapperCol={{ span: 8 }}
      initialValues={{
        period_str: 'months',
        period_num: 1,
        days_before: 0,
      }}
      {...props}
    >
      <Form.Item label="Número de periodos" name="period_num" labelAlign="left">
        <InputNumber style={{ width: '100%' }} min={0} />
      </Form.Item>
      <Form.Item label="Tipo de periodo" name="period_str" labelAlign="left">
        <Select style={{ width: '100%' }}>
          <Select.Option value="weeks">Semanas</Select.Option>
          <Select.Option value="months">Meses</Select.Option>
          <Select.Option value="years">Años</Select.Option>
        </Select>
      </Form.Item>
      <Form.Item label="Hora del periodo" name="period_time" labelAlign="left">
        <TimePicker
          placeholder="Seleccione la hora"
          format="hh:mm a"
          showHour
          showSecond={false}
          showMinute={false}
          style={{ width: '100%' }}
        />
      </Form.Item>
      <Form.Item
        label="Días antes de notificación"
        name="days_before"
        labelAlign="left"
      >
        <InputNumber style={{ width: '100%' }} min={0} />
      </Form.Item>
      <Form.Item
        label="Fecha inicial"
        name="init_date"
        labelAlign="left"
        rules={[
          { required: true, message: 'Por favor ingrese la fecha de inicio!' },
        ]}
      >
        <DatePickerDay
          style={{ width: '100%' }}
          placeholder="Seleccione la fecha"
          format="DD/MM/YYYY"
          disabledDate={(date) => date < dayjs()}
        />
      </Form.Item>
    </Form>
  );
};

export default ReminderForm;
