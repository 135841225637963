import { useSelector, useDispatch } from 'react-redux';
import { message } from 'antd';

import { persistor } from '../Redux/store';

import { Auth } from '../Redux/reducers/auth';

import { login } from '../Services/API';
import { Departments } from '../Redux/reducers/departments';

export function useAuth() {
  const { auth } = useSelector((state) => ({ auth: state.auth }));
  const dispatch = useDispatch();
  async function post(email, password) {
    dispatch(Auth.authRequest());
    let response = await login(email, password);
    if (response.ok) {
      let { accessToken, user } = response.data;
      dispatch(
        Auth.authSuccess(accessToken, user, {
          rol_name: user.rol_name,
          rol: user.rol,
        })
      );
    } else {
      dispatch(Auth.authFailure());
      message.error('Hubo un error, verifica tus datos.');
    }
  }
  function logout() {
    dispatch(Auth.authClear());
    dispatch(Departments.clearDepartment());
    persistor.flush();
    persistor.purge();
  }
  return [auth, post, logout];
}

export const useSetUser = function () {
  const dispatch = useDispatch();
  const set = (values) => {
    dispatch(Auth.authSetUser(values));
  };
  return [set];
};
