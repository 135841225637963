import React from 'react';
import * as Icons from 'react-feather';
import { Select } from 'antd';

const IconSelect = (props) => {
  return (
    <Select {...props} >
      {Object.keys(Icons).map((key) => {
        let Icon = Icons[key];
        return (
          <Select.Option value={key} key={key}>
            <Icon
              size={14}
              strokeWidth={1}
              className="anticon"
              style={{ marginRight: 10 }}
            />
            {key}
          </Select.Option>
        );
      })}
    </Select>
  );
};
export default IconSelect;
