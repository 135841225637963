import React from 'react';
import { Form, Input, InputNumber, Button, DatePicker, Divider, Select } from 'antd';

const { Option } = Select;

const EditInfoForm = ({ formRef, onSubmit, loading, ...props }) => {
  return (
    <Form
      form={formRef}
      onFinish={onSubmit}
      labelCol={{ span: 8 }}
      wrapperCol={{ span: 16 }}
      {...props}
    >
      <Form.Item label="Nombre completo" name="full_name" labelAlign="left">
        <Input />
      </Form.Item>
      <Form.Item label="Fecha de nacimiento" name="birthday" labelAlign="left">
        <DatePicker style={{ width: '100%' }} />
      </Form.Item>
      <Form.Item label="Teléfono" name="phone" labelAlign="left">
        <InputNumber />
      </Form.Item>
      <Divider />
      <Form.Item label="Estado" name="state" labelAlign="left">
        <Select style={{ width: '100%' }}>
          <Option value="Aguascalientes">Aguascalientes</Option>
          <Option value="Baja California">Baja California</Option>
          <Option value="Baja California Sur">Baja California Sur</Option>
          <Option value="Campeche">Campeche</Option>
          <Option value="Chiapas">Chiapas</Option>
          <Option value="Chihuahua">Chihuahua</Option>
          <Option value="Coahuila">Coahuila</Option>
          <Option value="Colima">Colima</Option>
          <Option value="Durango">Durango</Option>
          <Option value="Estado de México">Estado de México</Option>
          <Option value="Guanajuato">Guanajuato</Option>
          <Option value="Guerrero">Guerrero</Option>
          <Option value="Hidalgo">Hidalgo</Option>
          <Option value="Jalisco">Jalisco</Option>
          <Option value="Michoacán">Michoacán</Option>
          <Option value="Morelos">Morelos</Option>
          <Option value="Nayarit">Nayarit</Option>
          <Option value="Nuevo León">Nuevo León</Option>
          <Option value="Oaxaca">Oaxaca</Option>
          <Option value="Puebla">Puebla</Option>
          <Option value="Querétaro">Querétaro</Option>
          <Option value="Quintana Roo">Quintana Roo</Option>
          <Option value="San Luis Potosí">San Luis Potosí</Option>
          <Option value="Sinaloa">Sinaloa</Option>
          <Option value="Sonora">Sonora</Option>
          <Option value="Tabasco">Tabasco</Option>
          <Option value="Tamaulipas">Tamaulipas</Option>
          <Option value="Tlaxcala">Tlaxcala</Option>
          <Option value="Veracruz">Veracruz</Option>
          <Option value="Yucatán">Yucatán</Option>
          <Option value="Zacatecas">Zacatecas</Option>
        </Select>
      </Form.Item>
      <Form.Item label="Ciudad" name="city" labelAlign="left">
        <Input />
      </Form.Item>
      <Form.Item label="Domicilio" name="address" labelAlign="left">
        <Input />
      </Form.Item>
      <Form.Item label="Código postal" name="zip_code" labelAlign="left">
        <Input />
      </Form.Item>
      <Form.Item style={{ textAlign: 'center' }}>
        <Button type="primary" htmlType="submit" size="large" loading={loading}>
          Guardar
        </Button>
      </Form.Item>
    </Form>
  );
};

export default EditInfoForm;
