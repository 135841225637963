import React, { useState, useEffect } from 'react';
import { Divider, Card, Row, Button, Table, Modal, Tag } from 'antd';
//Hooks
import { useFetchImportLogs } from '../../Hooks/Logs.hook';
let timeout = null;
const ImportLogs = () => {
  const [logs, setLogs] = useState(null);
  const [labels, loading, , query, update] = useFetchImportLogs([
    { field: '$sort', value: true },
  ]);
  const [modal, setModal] = useState(false);
  useEffect(() => {
    timeout = setInterval(() => {
      update();
    }, [5000]);
    return () => {
      clearInterval(timeout);
    }; // eslint-disable-next-line
  }, []);
  const columns = [
    {
      title: 'Tipo',
      dataIndex: 'type',
    },
    {
      title: 'Agregados',
      dataIndex: 'inserted',
    },
    {
      title: 'Actualizados',
      dataIndex: 'updated',
    },
    {
      title: 'Total',
      dataIndex: 'total',
    },
    {
      title: 'Error',
      dataIndex: 'failure',
      render: (e) =>
        !e ? (
          <Tag color="green">Sin errores</Tag>
        ) : (
          <Tag color="red">Ha ocurrido un error</Tag>
        ),
    },
    {
      title: 'Estatus',
      dataIndex: 'status',
      render: (e) =>
        e ? (
          <Tag color="green">Finalizado</Tag>
        ) : (
          <Tag color="red">Sin finalizar</Tag>
        ),
    },
    {
      title: '',
      dataIndex: '_id',
      width: 200,
      render(e, row) {
        return (
          <Row justify="space-between" gutter={[10, 10]}>
            <Button
              type="primary"
              onClick={() => {
                setLogs(row.log);
                setModal(true);
              }}
            >
              Ver registro
            </Button>
          </Row>
        );
      },
    },
  ];
  return (
    <Card className="slideInLeft">
      <Divider orientation="left">Registro de importación</Divider>
      <Modal
        visible={modal}
        onCancel={() => {
          setModal(false);
          setLogs();
        }}
        footer={[
          <Button
            key="ok"
            onClick={() => {
              setModal(false);
              setLogs();
            }}
          >
            Cerrar
          </Button>,
        ]}
        title="Registro "
      >
        {logs?.length > 0 ? JSON.stringify(logs) : 'Sin registros'}
      </Modal>
      <Table
        loading={loading}
        dataSource={labels.data}
        rowKey={(row) => row._id}
        columns={columns}
        locale={{ emptyText: 'Sin registros' }}
        pagination={{
          total: labels.total,
          current: labels.skip / 10 + 1,
          onChange: (e) => query([], (e - 1) * 10),
        }}
      />
    </Card>
  );
};

export default ImportLogs;
