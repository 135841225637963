import React, { useEffect, useState, useCallback } from 'react';
import {
  Card,
  Divider,
  Skeleton,
  List,
  Button,
  Row,
  Collapse,
  Modal,
  message,
} from 'antd';
import RequestList from '../../Components/Captura/RequestList';
import FileSelection from '../../Components/Captura/FileSelection';
//Hooks
import { useGetDepartmentByName } from '../../Hooks/Departments.hook';
import { useNavigation } from '../../Hooks/Nav.hook';
import { useAuth } from '../../Hooks/Auth.hook';
//Utils
import { getAssignments, createRequest } from '../../Services/API';

const { Panel } = Collapse;
const _getModule = (path) =>
  path.replace('/dashboard/modulo/', '').split('/')[0] || false;

const CapturaInformacion = () => {
  const [{ pathname }] = useNavigation();
  const [data, setData] = useState([]);
  const [modal, setModal] = useState(false);
  const [filetype, setFiletype] = useState(null);
  const [fileBody, setFileBody] = useState({ files: [], description: '' });
  const [loading, setLoading] = useState(false);
  const [modalLoading, setModalLoading] = useState(false);
  const [
    {
      user: { _id },
    },
  ] = useAuth();
  const [department] = useGetDepartmentByName(_getModule(pathname));
  const get = useCallback(
    async (queries, skip, limit) => {
      let memo = {},
        items = [];
      setLoading(true);
      let response = await getAssignments(queries, skip, limit);
      if (response.ok) {
        for (let record of response.data.data) {
          for (let file of record.files) {
            if (!memo[file.type_id]) {
              memo[file.type_id] = true;
              items.push(file);
            }
          }
        }
        setData(items);
      }
      setLoading(false);
    },
    [setData, setLoading]
  );
  useEffect(() => {
    if (department._id && _id) {
      get([
        {
          field: 'client_id',
          value: _id,
        },
        {
          field: 'department_id',
          value: department._id,
        },
      ]);
    }
  }, [department._id, _id, get]);
  const _createRequest = async () => {
    setModalLoading(true);
    let response = await createRequest({
      type_id: filetype,
      history: [
        {
          attachments: fileBody.files.map((e) => e._id),
          description:
            fileBody.description !== '' ? fileBody.description : undefined,
          view: 0,
        },
      ],
      status: 1,
      client_id: _id,
      department_id: department._id,
    });
    if (response.ok) {
      message.success(
        'Se han registrado los archivos correctamente, actualizando...'
      );
      setModal(false);
      setFiletype(null);
      setFileBody({ files: [], description: '' });
      get([
        {
          field: 'client_id',
          value: _id,
        },
        {
          field: 'department_id',
          value: department._id,
        },
      ]);
    } else {
      message.error('Ha ocurrido un error: ' + response.data.message);
    }
    setModalLoading(false);
  };
  if (loading) {
    return (
      <Card className="slideInLeft">
        <Skeleton active />
      </Card>
    );
  }
  return (
    <Card className="slideInLeft ">
      <Divider orientation="left">Captura de información</Divider>
      <Modal
        title="Agregar archivos"
        visible={modal}
        okText="Agregar"
        centered
        okButtonProps={{ loading: modalLoading }}
        cancelText="Cancelar"
        onOk={_createRequest}
        onCancel={() => {
          setModal(false);
          setFiletype(null);
          setFileBody({ files: [], description: '' });
        }}
      >
        {filetype !== null && (
          <FileSelection body={fileBody} handler={setFileBody} />
        )}
      </Modal>
      <span>
        Para poder proveerle los servicios necesarios, requerimos de ud. los
        siguientes documentos:
      </span>
      <div className="container-captura">
        <List
          style={{ marginBottom: 20 }}
          bordered
          dataSource={data}
          locale={{ emptyText: 'Sin archivos requeridos' }}
          renderItem={(item) => (
            <List.Item key={item._id}>{item.name}</List.Item>
          )}
        />
      </div>
      <span>
        Favor de utilizar nuestro módulo de captura para subir dichos
        documentos. Más de un documento puede ser utilizado a la vez.
      </span>
      <div className="upload-zone">
        {data.map((row) => (
          <div key={row._id} style={{ marginBottom: 20 }}>
            <Divider orientation="left">{row.name}</Divider>
            <Collapse ghost style={{ marginBottom: 20 }}>
              <Panel header="Ver mis peticiones" key={row.type_id}>
                <RequestList
                  type={row.type_id}
                  user_id={_id}
                  department_id={department._id}
                />
              </Panel>
            </Collapse>
            <Row justify="end">
              <Button
                type="primary"
                onClick={() => {
                  setFiletype(row.type_id);
                  setModal(true);
                }}
              >
                Agregar archivos
              </Button>
            </Row>
          </div>
        ))}
      </div>
    </Card>
  );
};

export default CapturaInformacion;
