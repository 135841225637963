import React, { useState, useEffect, useCallback } from 'react';
import {
  Badge,
  Breadcrumb,
  Button,
  Card,
  Col,
  Descriptions,
  Divider,
  Form,
  List,
  Modal,
  Row,
  Table,
  message,
} from 'antd';
import { ArrowLeft, Download, Upload } from 'react-feather';
import dayjs from 'dayjs';

import {
  createCompany,
  createStaff,
  getCompanies,
  getStaffs,
  updateCompany,
  updateStaff,
} from '../../Services/API';
import { useAuth } from '../../Hooks/Auth.hook';
import StaffForm from '../../Components/Form/Staff.form';
import CompanyForm from '../../Components/Form/Company.form';
import FileSelection from '../../Components/Captura/FileSelection';
import { getFileType } from '../../Utils/parse';
import FileIcon from '../../Components/Files/FileIcon';
import { getServer } from '../../Utils/url';
import Filter from '../../Components/Dashboard/Filter';
import LoadingButton from '../../Components/Files/LoadingButton';
import PreviewButton from '../../Components/Files/PreviewButton';
import RemoveButton from '../../Components/Files/RemoveButton';

const Empleados = () => {
  const [auth] = useAuth();
  const [modal, setModal] = useState(false);
  const [modal2, setModal2] = useState(false);
  const [loading, setLoading] = useState(false);
  const [mediaModal, setMediaModal] = useState(false);
  const [modalLoading, setModalLoading] = useState(false);
  const [loadingCompany, setLoadingCompany] = useState(false);

  const [media, setMedia] = useState({});
  const [target, setTarget] = useState('attachments');
  const [employee, setEmployee] = useState({});
  const [company, setCompany] = useState(null);
  const [editCompany, setEditCompany] = useState(null);
  const [companyData, setCompanyData] = useState(null);

  const [formRef] = Form.useForm();
  const [formRefCompany] = Form.useForm();

  const [fileBody, setFileBody] = useState({ files: [], description: '' });
  const [data, setData] = useState({ data: [], total: 0, skip: 0 });
  const staffColumns = [
    {
      title: 'Nombre',
      dataIndex: 'full_name',
    },
    { title: 'Identificador', dataIndex: 'key' },
    { title: 'RFC', dataIndex: 'rfc' },
    {
      title: '',
      dataIndex: '_id',
      render(e, row) {
        return (
          <Row align="middle" justify="center" gutter={[10, 0]}>
            <Col>
              <Button
                onClick={() => {
                  setTarget('attachments');
                  setMedia(row);
                }}
                style={{
                  height: 'auto',
                }}
              >
                Archivos
                <br />
                Compañía
              </Button>
            </Col>
            <Col>
              <Button
                onClick={() => {
                  setTarget('personal');
                  setMedia(row);
                }}
                style={{
                  height: 'auto',
                }}
              >
                Archivos
                <br />
                Personales
              </Button>
            </Col>
            <Col>
              <Button
                type="primary"
                style={{ marginLeft: 8 }}
                onClick={() => {
                  formRef.setFieldsValue({
                    ...row,
                    birthday: dayjs(row.birthday),
                    date_admission: dayjs(row.date_admission),
                  });
                  setEmployee(row);
                  setModal(true);
                }}
              >
                Editar
              </Button>
            </Col>
          </Row>
        );
      },
    },
  ];

  const companyColumns = [
    { title: 'Nombre', dataIndex: 'name' },
    {
      title: 'Nombre Completo',
      dataIndex: 'full_name',
    },
    { title: 'RFC', dataIndex: 'rfc' },
  ];

  if (auth.role.rol === 'employee' || auth.role.rol === 'leader') {
    companyColumns.push({
      title: 'Cliente',
      dataIndex: 'owner',
    });
  }

  companyColumns.push({
    title: 'Estatus',
    dataIndex: 'status',
    render: column =>
      column ? (
        <Badge status="success" text="Activo" />
      ) : (
        <Badge status="error" text="Inactivo" />
      ),
  });

  companyColumns.push({
    title: '',
    dataIndex: '_id',
    render(id, row) {
      return (
        <>
          <Button
            type="primary"
            onClick={() => {
              setCompany(id);
              setCompanyData(row);
            }}
          >
            Seleccionar
          </Button>
          {(auth.role.rol === 'client' || auth.role.rol === 'leader') && (
            <Button
              type="primary"
              style={{ marginLeft: 10 }}
              onClick={() => {
                setEditCompany(id);
                setCompanyData(row);
              }}
            >
              Editar
            </Button>
          )}
        </>
      );
    },
  });

  const getEmployee = useCallback(
    async (skip = null, total, queries = []) => {
      setLoading(true);
      let response = await getStaffs(
        [{ field: 'company_id', value: company }, ...queries],
        skip == null ? data.skip : skip,
        total
      );
      if (response.ok) {
        if (response.data?.data) {
          for (let item of response.data.data) {
            if (item.attachments) {
              item.attachments.sort((a, b) => {
                if (a.real_name && b.real_name) {
                  let textA = a.real_name.toUpperCase(),
                    textB = b.real_name.toUpperCase();

                  return textA < textB ? -1 : textA > textB ? 1 : 0;
                }

                return 0;
              });
            }

            if (item.personal) {
              item.personal.sort((a, b) => {
                if (a.real_name && b.real_name) {
                  let textA = a.real_name.toUpperCase(),
                    textB = b.real_name.toUpperCase();

                  return textA < textB ? -1 : textA > textB ? 1 : 0;
                }

                return 0;
              });
            }
          }
        }
        setData(response.data);
      }
      setLoading(false);
    },
    [setLoading, setData, company, data.skip]
  );

  const getCompany = useCallback(
    async (skip, total, queries = []) => {
      setData({ data: [], total: 0, skip: 0 });
      setLoading(true);
      let response = await getCompanies(queries, skip, total);
      if (response.ok) {
        setData(response.data);
      }
      setLoading(false);
    },
    [setLoading, setData]
  );

  useEffect(() => {
    if (company === null) {
      getCompany();
    } else {
      getEmployee();
    }
  }, [company, getCompany, getEmployee]);

  useEffect(() => {
    if (editCompany && companyData) {
      formRefCompany.setFieldsValue({
        ...companyData,
        date_registration: dayjs(companyData.date_registration),
      });
      setModal2(true);
    }
  }, [editCompany, companyData, formRefCompany]);

  const _onSubmit = async values => {
    setModalLoading(true);
    let response = employee._id
      ? await updateStaff(employee._id, { ...values, company_id: company })
      : await createStaff({ ...values, company_id: company });
    if (response.ok) {
      formRef.resetFields();
      setModal(false);
      message.success(
        employee._id
          ? 'Empleado actualizado, actualizando...'
          : 'Empleado agregado, actualizando...'
      );
      setEmployee({});
      getEmployee();
    } else {
      message.error(
        employee._id
          ? 'Error actualizando empleado'
          : 'Error agregando empleado'
      );
      setEmployee({});
    }
    setModalLoading(false);
  };

  const onSubmitCompany = async values => {
    setLoadingCompany(true);
    let response = editCompany
      ? await updateCompany(editCompany, values)
      : await createCompany({ ...values, status: 1 });

    if (response.ok) {
      formRefCompany.resetFields();
      setModal2(false);
      setCompanyData(null);
      message.success(
        editCompany ? 'Empresa actualizada' : 'Se ha agregado el registro'
      );
      getCompany();
    } else {
      message.error(response.data.message);
    }

    setLoadingCompany(false);
  };

  const getFileName = (oFile, sDescription) => {
    if (!sDescription) {
      if (oFile.name) {
        return oFile.name.substr(0, oFile.name.lastIndexOf('.'));
      }
    }

    return sDescription;
  };

  const _addFile = async () => {
    setModalLoading(true);
    let response = await updateStaff(media._id, {
      $push: {
        [target]: fileBody.files.map(e => ({
          media_id: e._id,
          name: getFileName(e, fileBody.description),
        })),
      },
    });

    if (response.ok) {
      message.success(
        'Se han registrado el archivo correctamente, actualizando...'
      );
      setMediaModal(false);
      setFileBody({ files: [], description: '' });
      setMedia(response.data);
    } else {
      message.error('Ha ocurrido un error: ' + response.data.message);
    }
    setModalLoading(false);
  };

  const _renderTable = () => {
    if (company === null) {
      return (
        <div className="fadeIn">
          <Modal
            title={editCompany ? 'Detalles de la empresa' : 'Nueva empresa'}
            visible={modal2}
            onCancel={() => {
              setModal2(false);
              formRefCompany.resetFields();
              setEditCompany(null);
            }}
            centered
            onOk={() => {
              formRefCompany.submit();
            }}
            okText={editCompany ? 'Actualizar' : 'Agregar'}
            cancelText="Cancelar"
            okButtonProps={{ loading: loadingCompany }}
            width={600}
          >
            <CompanyForm
              formRef={formRefCompany}
              onSubmit={onSubmitCompany}
              userSearch
              edit={editCompany}
            />
          </Modal>
          <Divider orientation="left">
            Seleccione una empresa para continuar
          </Divider>
          {auth.role.rol === 'leader' && (
            <Row justify="end">
              <Col>
                <Button
                  type="primary"
                  style={{ marginBottom: 10 }}
                  onClick={() => setModal2(true)}
                >
                  Agregar una empresa
                </Button>
              </Col>
            </Row>
          )}
          <Filter
            selected="name"
            handler={e => getCompany(0, 10, e)}
            fields={[
              { title: 'Nombre', dataIndex: 'name' },
              {
                title: 'Nombre Completo',
                dataIndex: 'full_name',
              },
              { title: 'RFC', dataIndex: 'rfc' },
            ]}
          />
          <Table
            columns={companyColumns}
            loading={loading}
            dataSource={data.data}
            locale={{ emptyText: 'Sin empresas' }}
            rowKey={row => row._id}
            pagination={{
              total: data.total,
              current: data.skip / 10 + 1,
              onChange: e => getCompany((e - 1) * 10),
            }}
          />
        </div>
      );
    }

    if (media._id) {
      return (
        <div className="fadeIn">
          <Modal
            title="Agregar Archivo"
            visible={mediaModal}
            onCancel={() => {
              setMediaModal();
            }}
            centered
            onOk={_addFile}
            okText="Agregar"
            cancelText="Cancelar"
            okButtonProps={{
              loading: modalLoading,
              disabled: fileBody.files.length === 0,
            }}
          >
            <FileSelection body={fileBody} handler={setFileBody} />
          </Modal>
          <Breadcrumb>
            <Breadcrumb.Item>
              <Button
                type="link"
                onClick={() => {
                  setMedia({});
                  getEmployee();
                }}
              >
                <ArrowLeft className="anticon" size={15} strokeWidth={2} />
                Regresar
              </Button>
            </Breadcrumb.Item>
          </Breadcrumb>

          <Divider orientation="left">
            {target === 'personal'
              ? 'Archivos Personales'
              : 'Archivos de la Compañía'}
          </Divider>
          <Row style={{ marginBottom: 20 }}>
            <Button
              type="primary"
              icon={<Upload size={14} strokeWidth={2} className="anticon" />}
              onClick={() => setMediaModal(true)}
              style={{ marginRight: 8 }}
            >
              Subir archivo
            </Button>
            <Button
              type="link"
              href={`${getServer()}/download?token=${auth.token}&staff_id=${
                media._id
              }&target=${target}`}
              target="_blank"
              style={{ marginLeft: 8 }}
              icon={<Download size={16} strokeWidth={2} className="anticon" />}
            >
              Descargar todo
            </Button>
          </Row>
          <List
            itemLayout="horizontal"
            dataSource={media[target]}
            locale={{ emptyText: 'Sin Archivos' }}
            loading={loading}
            renderItem={item => {
              return (
                <List.Item
                  actions={[
                    <PreviewButton
                      path={item.file_path}
                      fileName={item.real_name}
                    />,
                    <LoadingButton
                      path={item.file_path}
                      fileName={item.real_name}
                    />,
                    <RemoveButton
                      file_id={item._id}
                      staff_id={media._id}
                      type={target}
                      handler={setMedia}
                    />,
                  ]}
                >
                  <List.Item.Meta
                    avatar={
                      <FileIcon
                        fileType={getFileType(item.real_name)}
                        style={{ fontSize: 40 }}
                        twoToneColor="#60A50D"
                      />
                    }
                    title={item.real_name}
                    description={item.name}
                  />
                </List.Item>
              );
            }}
          />
        </div>
      );
    }

    return (
      <div className="fadeIn">
        <Modal
          title={employee._id ? 'Actualizar Empleado' : 'Nuevo Empleado'}
          visible={modal}
          onCancel={() => {
            setModal(false);
            formRef.resetFields();
          }}
          centered
          onOk={() => {
            formRef.submit();
          }}
          okText={employee._id ? 'Actualizar' : 'Agregar'}
          cancelText="Cancelar"
          okButtonProps={{ loading: modalLoading }}
          width={600}
        >
          <StaffForm formRef={formRef} onSubmit={_onSubmit} />
        </Modal>
        <Breadcrumb>
          <Breadcrumb.Item>
            <Button
              type="link"
              onClick={() => {
                setCompany(null);
                setCompanyData(null);
              }}
            >
              <ArrowLeft className="anticon" size={15} strokeWidth={2} />
              Regresar
            </Button>
          </Breadcrumb.Item>
        </Breadcrumb>

        <Divider orientation="left">Empleados</Divider>
        <Row style={{ marginBottom: 20 }}>
          <Col span={18}>
            {companyData && (
              <Descriptions title="Información de la Empresa">
                <Descriptions.Item label="Nombre">
                  {companyData.name}
                </Descriptions.Item>
                <Descriptions.Item label="RFC">
                  {companyData.rfc}
                </Descriptions.Item>
                <Descriptions.Item label="Razón social">
                  {companyData.full_name}
                </Descriptions.Item>
              </Descriptions>
            )}
          </Col>
          <Col span={6}>
            <Row justify="end">
              <Button type="primary" onClick={() => setModal(true)}>
                Nuevo Empleado
              </Button>
            </Row>
          </Col>
        </Row>
        <Filter
          selected="full_name"
          fields={[
            {
              title: 'Nombre',
              dataIndex: 'full_name',
            },
            { title: 'Identificador', dataIndex: 'key' },
            { title: 'RFC', dataIndex: 'rfc' },
          ]}
          handler={e => getEmployee(0, 10, e)}
        />
        <Table
          columns={staffColumns}
          loading={loading}
          dataSource={data.data}
          locale={{ emptyText: 'Sin empleados' }}
          rowKey={row => row._id}
          pagination={{
            total: data.total,
            current: data.skip / 10 + 1,
            onChange: e => setData({ ...data, skip: (e - 1) * 10 }),
          }}
        />
      </div>
    );
  };

  return <Card className="slideInLeft">{_renderTable()}</Card>;
};
export default Empleados;
