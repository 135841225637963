import React from 'react';
import { Layout, Menu, Drawer, Button, Modal } from 'antd';
import { LogOut, Folder, Users } from 'react-feather';
import Header from '../../Components/Dashboard/Header';
import ModalError from '../../Components/ModalError';
import { ErrorBoundary } from 'react-error-boundary';

//Hooks
import { useMobile } from '../../Hooks/Responsive.hook';
import { useDrawer } from '../../Hooks/App.hook';
import { useAuth } from '../../Hooks/Auth.hook';
import { useNavigation } from '../../Hooks/Nav.hook';

//Constants
import { MenuRoutes, moduleRoutes } from '../../Share/Routes';
import { getVersion } from '../../Utils/version';

//Route
import SubRoutes from '../../Components/SubRoutes';
import { useFetchDepartmentsRedux } from '../../Hooks/Departments.hook';
import ErrorFallback from '../ErrorFallback';

const { Sider } = Layout;

const DashboardScreen = () => {
  const [mobile] = useMobile();
  const [
    {
      role: { rol },
      user: { _id },
    },
  ] = useAuth();
  const [departments] = useFetchDepartmentsRedux([
    { field: 'members.user_id', value: _id },
  ]);
  const [visible, onChange] = useDrawer();
  const [, , logout] = useAuth();
  const [location, navigate] = useNavigation();
  const logoutAlert = () => {
    Modal.confirm({
      title: '¿Deseas cerrar sesión?',
      content: 'Se perderán los datos que no hayas guardado',
      okText: 'Si',
      cancelText: 'No',
      onOk() {
        logout();
      },
    });
  };
  const _parseLocation = () => {
    let replaces = location.pathname
      .replace('/dashboard', '')
      .replace('/dashboard/', '');
    return replaces === '' ? '/' : replaces;
  };
  const _renderRoutes = () => {
    let routes = [
      ...MenuRoutes,
      ...(rol !== 'admin' ? departments : []).map(e => moduleRoutes(e)),
      {
        title: 'Archivos',
        key: '/files/',
        icon: <Folder size={14} strokeWidth={1} className="anticon" />,
        permission: ['admin', 'leader', 'client', 'employee'],
      },
    ];
    return routes.map(
      ({ title, hasChildren, children, permission, ...props }) => {
        if (!permission.includes(rol)) {
          return null;
        }
        return !hasChildren ? (
          <Menu.Item
            {...props}
            onClick={() => navigate(`/dashboard${props.key}`)}
          >
            {title}
          </Menu.Item>
        ) : (
          <Menu.SubMenu title={title} {...props}>
            {title === 'Nomina' &&
              ['employee', 'client', 'leader'].indexOf(rol) > -1 && (
              <Menu.Item
                {...{ key: props.key + '/empleados' }}
                onClick={() => navigate(`/dashboard${props.key}/empleados`)}
                icon={<Users size={14} strokeWidth={1} className="anticon" />}
              >
                Empresas
              </Menu.Item>
            )}
            {children.map(data => {
              if (!data.permission.includes(rol)) {
                return null;
              }
              return (
                <Menu.Item
                  {...{ ...data, key: props.key + data.key }}
                  onClick={() => navigate(`/dashboard${props.key}${data.key}`)}
                >
                  {data.title}
                </Menu.Item>
              );
            })}
          </Menu.SubMenu>
        );
      }
    );
  };

  const _renderPowerBy = () => {
    return (
      <div className="power-by">
        <a
          href="https://obsidiansoft.io/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <span>Power by</span>
          <img src="/images/power-by.png" alt="power by Obsidian" />
        </a>
        <div className="version">
          <span>{getVersion()}</span>
        </div>
      </div>
    );
  };

  return (
    <Layout className="dashboard-screen fadeIn">
      <Header />
      <ModalError />
      {mobile && (
        <Drawer
          title={
            <span className="brand">
              <img src="/images/logo.svg" width="24px" alt="MLC" />
              <strong className="mx-1 text-black">MLC</strong>
            </span>
          }
          placement="left"
          closable={false}
          visible={visible}
          onClose={() => onChange(false)}
          bodyStyle={{ padding: 0 }}
        >
          <Menu
            mode="inline"
            defaultSelectedKeys={[_parseLocation()]}
            style={{ height: '90%', borderRight: 0 }}
            theme="dark"
          >
            {_renderRoutes()}
          </Menu>
          <div className="text-center bg-menu">
            <Button
              type="link"
              icon={<LogOut size={26} strokeWidth={1} className="anticon" />}
              onClick={logoutAlert}
            />
            {_renderPowerBy()}
          </div>
        </Drawer>
      )}
      <Layout>
        <Sider
          width={250}
          className="site-layout-background"
          collapsed={mobile}
          collapsedWidth={0}
          theme="dark"
        >
          <Menu
            mode="inline"
            theme="dark"
            defaultSelectedKeys={[_parseLocation()]}
            style={{ height: '90%', borderRight: 0 }}
          >
            {_renderRoutes()}
          </Menu>
          <div className="text-center bg-menu">
            <Button
              type="link"
              icon={<LogOut size={30} strokeWidth={1} className="anticon" />}
              onClick={logoutAlert}
            />
            {_renderPowerBy()}
          </div>
        </Sider>
        <Layout className="main-container">
          <ErrorBoundary FallbackComponent={ErrorFallback}>
            <SubRoutes />
          </ErrorBoundary>
        </Layout>
      </Layout>
    </Layout>
  );
};

export default DashboardScreen;
