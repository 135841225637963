import React from 'react';
import { Card, Result, Button } from 'antd';

import { useNavigation } from '../../Hooks/Nav.hook';

const ErrorMessage = ({ error }) => {
  const [, nav] = useNavigation();
  return (
    <Card className="slideInLeft">
      <Result
        status="warning"
        title="No tiene permisos para ver esta sección"
        extra={
          <Button
            type="primary"
            key="console"
            onClick={() => nav('/dashboard/')}
          >
            Ir a inicio
          </Button>
        }
      />
    </Card>
  );
};

export default ErrorMessage;
