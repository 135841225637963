import React from 'react';
import { Route } from 'react-router-dom';
import UnAuthorized from '../../Screens/UnAuthorized';
import { useAuth } from '../../Hooks/Auth.hook';

const RoleRoute = ({
  component: Component,
  roles = [],
  redirect,
  ...rest
}) => {
  const [
    {
      role: { rol },
    },
  ] = useAuth();
  return (
    <Route
      {...rest}
      render={(props) => {
        if (roles.includes(rol)) {
          return <Component {...props} />;
        } else {
          return <UnAuthorized {...props} />;
        }
      }}
    />
  );
};

export default RoleRoute;
