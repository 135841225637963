import React, { useState, useCallback, useEffect } from 'react';
import { Table, Button, Modal, message } from 'antd';
import dayjs from 'dayjs';
import { useNavigation } from '../../Hooks/Nav.hook';
import { getRequests, deleteRequest } from '../../Services/API';
import { useAuth } from '../../Hooks/Auth.hook';
import { Trash } from 'react-feather';

const RequestTable = ({ department_id, client_id, filetype_id }) => {
  const [
    {
      user: { _id },
    },
  ] = useAuth();
  const [, nav] = useNavigation();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({ data: [], total: 0, skip: 0 });
  const get = useCallback(async () => {
    setLoading(true);
    let response = await getRequests([
      {
        field: 'department_id',
        value: department_id,
      },
      {
        field: 'client_id',
        value: client_id,
      },
      {
        field: 'type_id',
        value: filetype_id,
      },
    ]);
    if (response.ok) {
      setData(response.data);
    }
    setLoading(false);
  }, [department_id, client_id, filetype_id, setData, setLoading]);
  useEffect(() => {
    if (department_id && client_id && filetype_id) {
      get();
    }
  }, [get, department_id, client_id, filetype_id]);
  const columns = [
    {
      title: 'Fecha',
      dataIndex: 'created_at',
      render(row) {
        return dayjs(row).format('DD/MM/YYYY hh:ss a');
      },
    },
    {
      title: '',
      dataIndex: '_id',
      render(row, body) {
        return (
          <div>
            <Button
              type="primary"
              onClick={() => nav(`/dashboard/request/${row}`)}
            >
              Ver
            </Button>
            {body.user_id === _id && (
              <Button
                style={{ marginLeft: 10 }}
                danger
                type="primary"
                icon={<Trash className="anticon" size={18} />}
                onClick={() => {
                  Modal.confirm({
                    title: '¿Quieres eliminar este elemento?',
                    okText: 'Si',
                    cancelText: 'No',
                    async onOk() {
                      let response = await deleteRequest(row);
                      if (response.ok) {
                        message.success('Elemento eliminado, actualizando...');
                        get();
                      } else {
                        message.error('No se pudo eliminar el elemento');
                      }
                    },
                  });
                }}
              />
            )}
          </div>
        );
      },
    },
  ];
  return (
    <Table
      dataSource={data.data}
      columns={columns}
      style={{ width: '100%' }}
      locale={{ emptyText: 'Sin solicitud de archivos' }}
      loading={loading}
      rowKey={(row) => row._id}
    />
  );
};

export default RequestTable;
