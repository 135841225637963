import React, { useState } from 'react';
import {
  Card,
  Divider,
  Button,
  Table,
  Modal,
  Form,
  message,
  Row,
  Col,
} from 'antd';
import { departmentColumn } from '../../Share/Columns';
//Hooks
import { useFetchDepartments } from '../../Hooks/Departments.hook';
//Form
import DepartmentForm from '../../Components/Form/Department.form';
//API
import { createDepartments, updateDepartments } from '../../Services/API';
import Filter from '../../Components/Dashboard/Filter';

const Departments = () => {
  const [modalLoading, setModalLoading] = useState(false);
  const [departmentId, setDepartmentId] = useState(null);
  const [files, loading, , update] = useFetchDepartments();
  const [formRef] = Form.useForm();
  const [modal, setModal] = useState(false);
  const columns = [
    ...departmentColumn,
    {
      title: '',
      dataIndex: '_id',
      render(e, row) {
        return (
          <Button
            type="primary"
            onClick={() => {
              setDepartmentId(e);
              formRef.setFieldsValue(row);
              setModal(true);
            }}
          >
            Editar
          </Button>
        );
      },
    },
  ];
  const onSubmit = async (values) => {
    setModalLoading(true);
    let response =
      departmentId !== null
        ? await updateDepartments(departmentId, values)
        : await createDepartments({ ...values, status: 1 });
    if (response.ok) {
      formRef.resetFields();
      setModal(false);
      message.success(
        departmentId !== null
          ? 'Registro actualizado'
          : 'Se ha agregado el registro'
      );
      update();
      setDepartmentId(null);
    } else {
      message.error(response.data.message);
    }
    setModalLoading(false);
  };
  return (
    <Card className="slideInLeft">
      <Divider orientation="left">Módulos</Divider>
      <Modal
        visible={modal}
        title={departmentId !== null ? 'Actualizar módulo' : 'Nuevo módulo'}
        okText={departmentId !== null ? 'Actualizar' : 'Agregar'}
        centered
        okButtonProps={{ loading: modalLoading }}
        cancelText="Cancelar"
        onOk={() => formRef.submit()}
        onCancel={() => {
          setModal(false);
          setDepartmentId(null);
          formRef.resetFields();
        }}
      >
        <DepartmentForm formRef={formRef} onSubmit={onSubmit} />
      </Modal>
      <Row>
        <Col flex="1">
          <Filter
            handler={update}
            fields={[
              {
                title: 'Nombre',
                dataIndex: 'name',
              },
            ]}
          />
        </Col>
        <Col flex="0">
          <Button
            type="primary"
            style={{ marginBottom: 10 }}
            onClick={() => setModal(true)}
          >
            Agregar
          </Button>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Table
            columns={columns}
            dataSource={files.data}
            rowKey={(row) => row._id}
            loading={loading}
          />
        </Col>
      </Row>
    </Card>
  );
};

export default Departments;
