import React, { useEffect, useState } from 'react';
import { Form, Input, Tabs, DatePicker, Select } from 'antd';
//Hooks
import { useFetchCountries } from '../../Hooks/Countries.hook';
import { useFetchRoles } from '../../Hooks/Roles.hook';
import { useQueryUser } from '../../Hooks/Users.hook';

const { TabPane } = Tabs;
const re = /^([A-ZÑ&]{3,4}) ?(?:- ?)?(\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])) ?(?:- ?)?([A-Z\d]{2})([A\d])$/;
let timeout = null;
const CompanyForm = ({
  formRef,
  onSubmit,
  loading,
  userSearch = false,
  edit = false,
  ...props
}) => {
  const user_id = formRef.getFieldValue('user_id');
  const [countries] = useFetchCountries([
    { field: '$select[]', value: 'name' },
    { field: '$select[]', value: 'code_2' },
    { field: '$select[]', value: 'states' },
    { field: 'status', value: 1 },
  ]);
  const [roles, loadingRole] = useFetchRoles();
  const [users, loadingUser, , query] = useQueryUser();
  const [mount, setMount] = useState(false);
  const _handleSearchUser = (value) => {
    if (timeout) {
      clearTimeout(timeout);
      timeout = null;
    }
    timeout = setTimeout(async () => {
      await query([
        { field: 'full_name', value: value, operator: '$regex' },
        { field: 'full_name', value: 'i', operator: '$options' },
        {
          field: 'rol_id',
          value: roles.filter((rol) => rol.group === 'client')[0]._id,
        },
      ]);
    }, 700);
  };
  useEffect(() => {
    async function get() {
      await query([{ field: '_id', value: user_id }]);
      setMount(true);
    }
    if (edit) {
      if (user_id) {
        get();
      }
    } else {
      setMount(true);
    }
    return () => {
      setMount(false);
    };
    // eslint-disable-next-line
  }, [formRef, edit, user_id, setMount]);
  return (
    <Form
      form={formRef}
      onFinish={onSubmit}
      labelCol={{ span: 8 }}
      wrapperCol={{ span: 16 }}
      {...props}
    >
      <Tabs defaultActiveKey="1">
        <TabPane tab="Datos generales" key="1">
          {userSearch && (
            <Form.Item
              label="Administrador"
              name="user_id"
              labelAlign="left"
              rules={[
                {
                  required: true,
                  message: 'Por favor ingrese el administrador!',
                },
              ]}
            >
              {!loadingRole & mount ? (
                <Select
                  placeholder="Buscar cliente"
                  showSearch
                  showArrow={false}
                  filterOption={false}
                  notFoundContent={null}
                  loading={loadingUser}
                  disabled={loadingRole}
                  style={{ width: '100%' }}
                  onSearch={_handleSearchUser}
                >
                  {users.data.map((user) => (
                    <Select.Option value={user._id} key={user._id}>
                      {user.full_name}
                    </Select.Option>
                  ))}
                </Select>
              ) : (
                'Cargando contenido...'
              )}
            </Form.Item>
          )}
          <Form.Item
            label="Nombre"
            name="name"
            labelAlign="left"
            rules={[
              { required: true, message: 'Por favor ingrese el nombre!' },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="RFC"
            name="rfc"
            labelAlign="left"
            hasFeedback
            rules={[
              { required: true, message: 'Por favor ingrese el RFC!' },
              ({ getFieldValue }) => ({
                validator(rule, value) {
                  if (value || value.match(re)) {
                    return Promise.resolve();
                  }
                  return Promise.reject('RFC no valido');
                },
              }),
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Razón social"
            name="full_name"
            labelAlign="left"
            rules={[
              {
                required: true,
                message: 'Por favor ingrese la razón social!',
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Regimén de actividad"
            name="tax_regime"
            labelAlign="left"
            rules={[
              {
                required: true,
                message: 'Por favor ingrese el regimén de actividad!',
              },
            ]}
          >
            <Input />
          </Form.Item>
        </TabPane>
        <TabPane tab="Registro" key="3">
          <Form.Item
            label="Fecha de registro"
            name="date_registration"
            labelAlign="left"
            rules={[{ required: true, message: 'Por favor ingrese la fecha!' }]}
          >
            <DatePicker format="DD/MM/YYYY" />
          </Form.Item>
          <Form.Item
            label="Tipo de empresa"
            name="turn_company"
            labelAlign="left"
            rules={[
              {
                required: true,
                message: 'Por favor ingrese el tipo de empresa!',
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Resumén de empresa"
            name="company_resume"
            labelAlign="left"
            rules={[{ required: true, message: 'Requerido!' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Sitio web"
            name="website"
            labelAlign="left"
          >
            <Input />
          </Form.Item>
        </TabPane>
        <TabPane tab="Dirección" key="2">
          <Form.Item
            label="País"
            name="country"
            labelAlign="left"
          >
            <Select style={{ width: '100%' }}>
              {countries.data.map((country) => (
                <Select.Option value={country.code_2} key={country.code_2}>
                  {country.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item shouldUpdate noStyle>
            {() => {
              let country = formRef.getFieldValue('country'),
                filtered = countries.data.filter(
                  (e) => e.code_2 === country
                )[0];

              if (!country) {
                return (
                  <Form.Item
                    label="Estado"
                    name="state"
                    labelAlign="left"
                  >
                    <Select disabled></Select>
                  </Form.Item>
                );
              }

              return (
                <Form.Item
                  label="Estado"
                  name="state"
                  labelAlign="left"
                >
                  <Select>
                    {filtered.states.map((row, i) => (
                      <Select.Option key={i} value={row}>
                        {row}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              );
            }}
          </Form.Item>
          <Form.Item
            label="Ciudad"
            name="city"
            labelAlign="left"
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Dirección"
            name="address"
            labelAlign="left"
            rules={[
              { required: true, message: 'Por favor ingrese la dirección!' },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Código postal"
            name="zip_code"
            labelAlign="left"
          >
            <Input />
          </Form.Item>
        </TabPane>
      </Tabs>
    </Form>
  );
};

export default CompanyForm;
