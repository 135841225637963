import React, { useState } from 'react';
import { Avatar, Card, Timeline, Descriptions, Button } from 'antd';

import { File } from 'react-feather';
import dayjs from 'dayjs';

import { useNavigation } from '../../Hooks/Nav.hook';
import { useFetchNotification } from '../../Hooks/Notification.hook';
import { updateNotification } from '../../Services/API';

const Actividad = (props) => {
  const [, nav] = useNavigation();
  const [notification] = useFetchNotification();
  const [notifications] = useState([
    {
      title: 'Inicio de sesión',
      subtitle: 'Hace unos segundos',
      avatar: (
        <Avatar
          size="small"
          style={{ color: '#f56a00', backgroundColor: '#fde3cf' }}
        >
          M
        </Avatar>
      ),
      body: 'Hace unos pocos segundos inició sesión en el sistema.',
    },
    {
      title: 'Bienvenido al sistema',
      subtitle: 'Hace unos segundos',
      body: `Bienvenido al sistema. Ud. podrá ver sus contactos y perfil
dentro del utilizando esta página. Si cuenta con más accesos a otros módulos,
puede accederlos en el menú de la izquierda.`,
    },
  ]);
  const _removeNotify = async (_notification) =>
    await updateNotification(
      _notification.request_id,

      {
        $set: {
          'history.$.view': 1,
        },
      },
      [{ field: 'history._id', value: _notification._id }]
    );

  return (
    <Card bordered={false} className="slideInLeft">
      <Timeline className="p-3">
        {[
          ...notification.data.map((row) => ({
            title: 'Archivo Pendiente de subida',
            subtitle: dayjs(row.date).format('DD/MM/YYYY hh:mm a'),
            avatar: (
              <Avatar
                size="small"
                style={{ color: '#876800', backgroundColor: '#ffec3d' }}
              >
                <File size={12} />
              </Avatar>
            ),
            body: (
              <Descriptions>
                <Descriptions.Item label="Tipo de archivo">
                  {row.type}
                </Descriptions.Item>
                <Descriptions.Item label="Departamento">
                  {row.department}
                </Descriptions.Item>
                <Descriptions.Item>
                  <Button
                    type="primary"
                    onClick={() => {
                      _removeNotify(row);
                      nav(`/dashboard/request/${row.request_id}`);
                    }}
                  >
                    Ver
                  </Button>
                </Descriptions.Item>
              </Descriptions>
            ),
          })),
          ...notifications,
        ].map((item, index) => (
          <Timeline.Item key={index} dot={item.avatar && item.avatar}>
            <div className="ml-4">
              <span
                css={`
                  display: block;
                  color: #000;
                `}
              >
                {item.title}
              </span>
              <br />
              <small>{item.subtitle}</small>

              <div>{item.body}</div>
            </div>
          </Timeline.Item>
        ))}
      </Timeline>
    </Card>
  );
};

export default Actividad;
