import React, { useEffect, useState, useCallback } from 'react';
import { Card, Divider, Table, Button, Modal } from 'antd';
import { userColumn } from '../../Share/Columns';
//Hooks
import { useFetchRolesM } from '../../Hooks/Roles.hook';

//API
import { getUsers } from '../../Services/API';
import Assignment from '../../Components/Relaciones/Assignment';
import Filter from '../../Components/Dashboard/Filter';

const Relaciones = () => {
  const [loading, setLoading] = useState(false);
  const [roles] = useFetchRolesM([
    { field: 'group', value: 'employee', operator: '$or' },
    { field: 'group', value: 'client', operator: '$or' },
    { field: '$select[]', value: '_id' },
    { field: '$select[]', value: 'name' },
    { field: '$select[]', value: 'group' },
  ]);
  const [user, setUser] = useState(null);
  const [modal, setModal] = useState(false);
  const [clients, setClients] = useState({ data: [] });
  const client = roles.filter((rol) => rol.group === 'client')[0] || null;
  const columns = [
    ...userColumn,
    {
      title: '',
      dataIndex: '_id',
      render(data, row) {
        return (
          <Button
            type="primary"
            onClick={() => {
              setUser(row);
              setModal(true);
            }}
          >
            Ver asignaciones
          </Button>
        );
      },
    },
  ];

  const fetchClients = useCallback(
    async (fields = []) => {
      if (client !== null) {
        setLoading(true);
        let response = await getUsers([
          { field: 'rol_id', value: client._id },
          ...fields,
        ]);
        if (response.ok) {
          setClients(response.data);
        }
        setLoading(false);
      }
    },
    [setClients, setLoading, client]
  );

  const nextPage = async (iPage) => {
    if (client !== null) {
      setLoading(true);
      let response = await getUsers(
        [{ field: 'rol_id', value: client._id }],
        (iPage - 1) * 10
      );
      if (response.ok) {
        setClients(response.data);
      }
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchClients();
  }, [fetchClients]);

  return (
    <Card className="slideInLeft">
      <Divider orientation="left">Relaciones</Divider>
      <Modal
        title="Asignaciones"
        visible={modal}
        centered
        onCancel={() => setModal(false)}
        footer={[
          <Button
            type="primary"
            key="cancel"
            onClick={() => {
              setModal(false);
              setUser(null);
            }}
          >
            Cerrar
          </Button>,
        ]}
      >
        {user !== null && <Assignment user={user} />}
      </Modal>
      <div>
        <Filter
          handler={fetchClients}
          fields={[
            {
              title: 'Nombre Completo',
              dataIndex: 'full_name',
            },
            {
              title: 'Email',
              dataIndex: 'email',
            },
            {
              title: 'Estatus',
              dataIndex: 'status',
            },
          ]}
        />
        <Table
          dataSource={clients.data}
          columns={columns}
          loading={loading}
          rowKey={(row) => row._id}
          className="responsive-table"
          locale={{ emptyText: 'Sin registros' }}
          pagination={{
            total: clients.total,
            current: clients.skip / 10 + 1,
            onChange: (e) => nextPage(e),
          }}
        />
      </div>
    </Card>
  );
};
export default Relaciones;
