import React, { useEffect } from 'react';
import { Card, Form, message } from 'antd';
import dayjs from 'dayjs';
//Forms
import EditInfoForm from '../Form/EditInfo.form';
//Hooks
import { useAuth, useSetUser } from '../../Hooks/Auth.hook';
import { updateUser } from '../../Services/API';

const EditarPerfil = () => {
  const [{ user }] = useAuth();
  const [set] = useSetUser();
  const [formRef] = Form.useForm();
  useEffect(() => {
    formRef.setFieldsValue({ ...user, birthday: dayjs(user.birthday) });
  }, [user, formRef]);
  const onSubmit = async function (values) {
    if (values.phone) {
      values.phone = String(values.phone);
    }

    let response = await updateUser(user._id, values);
    if (response.ok) {
      set(response.data);
      message.success('Datos actualizados');
    } else {
      message.error('Error actualizando datos: ' + response.data.message);
    }
  };
  return (
    <Card className="slideInLeft">
      <EditInfoForm formRef={formRef} onSubmit={onSubmit} />
    </Card>
  );
};

export default EditarPerfil;
