import React, { useCallback, useState, useEffect } from 'react';
import {
  Card,
  Divider,
  Skeleton,
  Avatar,
  Table,
  Row,
  Col,
  Select,
  Button,
  message,
  Popover,
  Menu,
  Modal,
} from 'antd';
//Hooks
import { useAuth } from '../../Hooks/Auth.hook';
import { useNavigation } from '../../Hooks/Nav.hook';
import { useGetDepartmentByName } from '../../Hooks/Departments.hook';
import { useQueryFileTypes } from '../../Hooks/FileTypes.hook';
//Utils
import {
  getAssignments,
  updateAssignments,
  createRequest,
} from '../../Services/API';
import { stringSeparate } from '../../Utils/parse';
import RequestTable from '../../Components/RequestUser/RequestTable';
import FileSelection from '../../Components/Captura/FileSelection';

const _getModule = (path) =>
  path.replace('/dashboard/modulo/', '').split('/')[0] || false;
const _getClient = (path) =>
  path.replace('/dashboard/modulo/', '').split('/')[2] || false;

let timeout = null;

const RequestUser = () => {
  const [
    {
      user: { _id },
    },
  ] = useAuth();

  const [auth] = useAuth();
  const [{ pathname }] = useNavigation();
  const [data, setData] = useState(null);
  const [files, loadingFiles, , query] = useQueryFileTypes();
  const [selected, setSelected] = useState(undefined);
  const [loading, setLoading] = useState(false);
  const [modal, setModal] = useState(false);
  const [filetype, setFiletype] = useState(null);
  const [fileBody, setFileBody] = useState({
    files: [],
    description: '',
    reminders: {},
  });
  const [modalLoading, setModalLoading] = useState(false);
  const [loadingMaster, setLoadingMaster] = useState(false);
  const [department] = useGetDepartmentByName(_getModule(pathname));
  const columns = [
    { title: 'Nombre del archivo', dataIndex: 'name' },
    {
      title: '',
      dataIndex: '_id',
      render(e, row) {
        return (
          <Popover
            placement="bottom"
            content={
              <Menu selectable={false}>
                {['employee', 'client'].indexOf(auth.role.rol) === -1 && (
                  <Menu.Item
                    onClick={() => {
                      Modal.confirm({
                        title: '¿Desea borrar este tipo de archivo?',
                        async onOk() {
                          return await _handleDeleteFile(e);
                        },
                      });
                    }}
                  >
                    Eliminar tipo de archivo
                  </Menu.Item>
                )}
                <Menu.Item
                  onClick={() => {
                    setModal(true);
                    setFiletype(row.type_id);
                  }}
                >
                  Agregar nueva solicitud
                </Menu.Item>
              </Menu>
            }
          >
            <Button type="link">Acciones</Button>
          </Popover>
        );
      },
    },
  ];
  const get = useCallback(async () => {
    setLoadingMaster(true);
    let response = await getAssignments([
      { field: 'user_id', value: _id },
      { field: 'department_id', value: department._id },
      { field: 'client_id', value: _getClient(pathname) },
    ]);
    if (response.ok) {
      setData(response.data.data[0] || false);
    }
    setLoadingMaster(false);
  }, [_id, department, pathname, setLoadingMaster]);

  useEffect(() => {
    if (department._id) {
      get();
    }
  }, [get, department._id]);
  const _createRequest = async () => {
    setModalLoading(true);
    let response = await createRequest({
      type_id: filetype,
      history: [
        {
          attachments: fileBody.files.map((e) => e._id),
          description:
            fileBody.description !== '' ? fileBody.description : undefined,
          view: 0,
        },
      ],
      status: 1,
      client_id: data.client_id,
      department_id: department._id,
      reminders:
        Object.keys(fileBody.reminders).length > 0
          ? fileBody.reminders
          : undefined,
    });
    if (response.ok) {
      message.success(
        'Se han registrado los archivos correctamente, actualizando...'
      );
      setModal(false);
      setFiletype(null);
      setFileBody({ files: [], description: '' });
      get();
    } else {
      message.error('Ha ocurrido un error: ' + response.data.message);
    }
    setModalLoading(false);
  };
  const _handleAddFile = async () => {
    setLoading(true);
    let response = await updateAssignments(data._id, {
      $push: {
        files: [
          {
            type_id: selected,
          },
        ],
      },
    });
    if (response.ok) {
      get();
      message.success('Tipo de archivo agregado');
    } else {
      message.error('Hubo un error agregando el archivo');
    }
    setSelected(undefined);
    setLoading(false);
  };
  const _handleDeleteFile = async (id) => {
    let response = await updateAssignments(data._id, {
      $pull: {
        files: { _id: id },
      },
    });
    if (response.ok) {
      get();
      message.success('Tipo de archivo eliminado');
    } else {
      message.error('Hubo un error eliminando el archivo');
    }
  };
  const _handleSearchFile = async (value) => {
    if (timeout) {
      clearTimeout(timeout);
      timeout = null;
    }
    timeout = setTimeout(async () => {
      await query([
        { field: 'name', value: value, operator: '$regex' },
        { field: 'name', value: 'i', operator: '$options' },
        ...data.files.map((row) => ({
          field: '_id',
          value: row.type_id,
          operator: '$nin',
        })),
      ]);
    });
  };
  if (!department._id || !pathname || data === null || loadingMaster) {
    return (
      <Card className="slideInLeft">
        <Skeleton active />
      </Card>
    );
  }
  return (
    <Card className="slideInLeft">
      <Divider orientation="left">Tipos de archivo</Divider>
      <Modal
        title="Agregar nueva solicitud"
        visible={modal}
        okText="Agregar"
        centered
        okButtonProps={{ loading: modalLoading }}
        cancelText="Cancelar"
        onOk={_createRequest}
        onCancel={() => {
          setModal(false);
          setFiletype(null);
          setFileBody({ files: [], description: '' });
        }}
      >
        {filetype !== null && (
          <FileSelection body={fileBody} handler={setFileBody} reminder />
        )}
      </Modal>
      <div className="card-bg">
        <div className="flex-tw">
          <div>
            <Avatar size={70}>{stringSeparate(data.client_name)}</Avatar>
          </div>
          <div>
            <h3>{data.client_name}</h3>
            <h4>Cliente </h4>
          </div>
          <div></div>
        </div>
      </div>
      <div className="content">
        {['client'].indexOf(auth.role.rol) === -1 && (
          <Row
            style={{ marginBottom: 20, marginTop: 20 }}
            justify="space-between"
          >
            <Col>
              <Select
                placeholder="Selecciona un tipo de archivo"
                showSearch
                showArrow={false}
                notFoundContent={null}
                style={{ width: 200 }}
                value={selected}
                loading={loadingFiles}
                filterOption={false}
                onSearch={_handleSearchFile}
                onChange={(e) => setSelected(e)}
              >
                {files.data.map((file) => (
                  <Select.Option value={file._id} key={file._id}>
                    {file.name}
                  </Select.Option>
                ))}
              </Select>
            </Col>
            <Col>
              <Button
                type="primary"
                disabled={!selected}
                onClick={_handleAddFile}
                loading={loading}
              >
                Agregar
              </Button>
            </Col>
          </Row>
        )}
        <Table
          columns={columns}
          dataSource={data.files}
          className="responsive-table"
          expandable={{
            expandedRowRender(record) {
              return (
                <RequestTable
                  filetype_id={record.type_id}
                  client_id={_getClient(pathname)}
                  department_id={department._id}
                />
              );
            },
          }}
          locale={{ emptyText: 'Sin archivos' }}
          rowKey={(row) => row._id}
        />
      </div>
    </Card>
  );
};

export default RequestUser;
