import React from 'react';
import { Result, Button } from 'antd';
import { useNavigation } from '../../Hooks/Nav.hook';
const UnAuthorized = () => {
  const [, nav] = useNavigation();
  return (
    <Result
      status="403"
      title="403"
      subTitle="No tienes permitido ver esta página."
      extra={
        <Button type="primary" onClick={() => nav(`/dashboard/`)}>
          Ir a inicio
        </Button>
      }
    />
  );
};

export default UnAuthorized;
