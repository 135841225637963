import { useEffect, useState } from 'react';
import { getDepartments } from '../Services/API';
import { useSelector, useDispatch } from 'react-redux';
import { Departments } from '../Redux/reducers/departments';
//Users
export function useFetchDepartments(queries = [], skip = 0, limit = 10) {
  const [loading, setLoading] = useState(true);
  const [params, setParams] = useState({ queries, skip, limit });
  const [error, setError] = useState(null);
  const [data, setData] = useState({ total: 0, data: [], skip: 0 });
  useEffect(() => {
    setLoading(true);
    getDepartments(params.queries, params.skip, params.limit).then(
      (response) => {
        if (response.ok) {
          setData(response.data);
        } else {
          setError(response.data);
        }
        setLoading(false);
      }
    );
  }, [params, setData, setError, setLoading]);
  function onChangeParams(q = [], s = 0, l = 10) {
    setParams({ queries: q, skip: s, limit: l });
  }
  async function update() {
    setLoading(true);
    let response = await getDepartments(
      params.queries,
      params.skip,
      params.limit
    );
    if (response.ok) {
      setData(response.data);
    } else {
      setError(response.data);
    }
    setLoading(false);
  }
  return [data, loading, error, onChangeParams, update];
}
export function useQueryDepartments() {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [data, setData] = useState({ total: 0, data: [], skip: 0 });
  async function update(queries, skip, limit) {
    setLoading(true);
    let response = await getDepartments(queries, skip, limit);
    if (response.ok) {
      setData(response.data);
    } else {
      setError(response.data);
    }
    setLoading(false);
  }
  return [data, loading, error, update];
}

//Redux
export function useFetchDepartmentsRedux(queries = [], skip = 0, limit = 10) {
  const { departments, loading, error, fetched } = useSelector(
    (state) => state.departments
  );
  const dispatch = useDispatch();
  const [params, setParams] = useState({ queries, skip, limit });
  useEffect(() => {
    if (departments.data.length === 0 && !fetched) {
      dispatch(Departments.departmentsRequest());
      getDepartments(params.queries, params.skip, params.limit).then(
        (response) => {
          if (response.ok) {
            dispatch(Departments.departmentsSuccess(response.data));
          } else {
            dispatch(Departments.departmentsFailure(response.data));
          }
        }
      );
    }
  }, [params, dispatch, departments.data, fetched]);
  function onChangeParams(q = [], s = 0, l = 10) {
    setParams({ queries: q, skip: s, limit: l });
  }
  async function update() {
    dispatch(Departments.departmentsRequest());
    getDepartments(params.queries, params.skip, params.limit).then(
      (response) => {
        if (response.ok) {
          dispatch(Departments.departmentsSuccess(response.data));
        } else {
          dispatch(Departments.departmentsFailure(response.data));
        }
      }
    );
  }
  return [departments.data, loading, error, onChangeParams, update];
}

export function useGetDepartmentByName(name) {
  const { departments } = useSelector((state) => state.departments);
  const [department, setDepartment] = useState({});
  useEffect(() => {
    if (name) {
      for (let item of departments.data) {
        if (item.name === name) {
          setDepartment(item);
        }
      }
    }
  }, [name, setDepartment, departments]);
  return [department];
}
