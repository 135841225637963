import React, { useCallback, useState } from 'react';
import {
  Row,
  Col,
  Select,
  Upload as UploadAntd,
  message,
  Button,
  Table,
  Divider,
  Modal,
  Empty,
  Descriptions,
  Card,
} from 'antd';
import { InboxOutlined } from '@ant-design/icons';
//Hooks
import { useAuth } from '../../Hooks/Auth.hook';

import { getServer } from '../../Utils/url';
import { exportCSV } from '../../Utils/export';
import { importCSV } from '../../Services/API';

const { Dragger } = UploadAntd;

const ImportScreen = () => {
  const [auth] = useAuth();
  const [key, setKey] = useState('staffs');
  const [modal, setModal] = useState(false);
  const [columns, setColumns] = useState([]);
  const [config, setConfig] = useState({});
  const [fields, setFields] = useState([]);
  const [fileList, setFileList] = useState([]);
  const [example, setExample] = useState([]);
  const [loadingModel, setLoadingModel] = useState(false);
  const get = useCallback(
    async (type) => {
      setLoadingModel(true);
      _handleClear();
      let response = await importCSV([
        { field: 'type', value: type },
        { field: 'required', value: 'false' },
      ]);
      if (response.ok) {
        let semiColumns = response.data.fields.map((e) => ({
          key: e,
          dataIndex: e,
          title: e,
        }));
        setConfig(response.data.config);
        setColumns(semiColumns);
        setExample(response.data.example);
        setFields(response.data.fields);
      }
      setLoadingModel(false);
    },
    [setColumns, setExample, setConfig, setFields]
  );
  const props = {
    fileList,
    name: 'files',
    multiple: false,
    action: `${getServer()}/import-csv`,
    headers: {
      Authorization: `bearer ${auth.token}`,
    },
    data: { type: key, required: 'false' },
    onChange(info) {
      const { status } = info.file;
      setFileList(info.fileList);
      if (status === 'done') {
        message.success('Archivo subido exitosamente, consulte el registro de importación.');
      } else if (status === 'error') {
        message.error('Hubo un error subiendo el archivo');
      }
    },
  };
  const _handleFetchData = async () => {
    await get(key);
  };
  const _handleClear = async () => {
    setFields([]);
    setConfig({});
    setColumns([]);
    setExample([]);
  };
  const _handleExportData = async () => {
    exportCSV(example, 'template', key);
  };
  const _showModal = () => {
    setModal(true);
  };
  const _handleOk = () => {
    setModal(false);
  };
  const _handleCancel = () => {
    setModal(false);
  };

  return (
    <Card className="slideInLeft import-screen">
      <Divider orientation="left">Importación</Divider>
      <Modal
        title="Upload"
        visible={modal}
        onOk={_handleOk}
        onCancel={_handleCancel}
      >
        <Dragger {...props}>
          <p className="ant-upload-drag-icon">
            <InboxOutlined />
          </p>
          <p className="ant-upload-text">
            Clic o arrastre el archivo para su subida
          </p>
          <p className="ant-upload-hint">
            Nota: Sólo soporta un archivos CSV por petición
          </p>
        </Dragger>
      </Modal>
      <h3>Modelo: </h3>
      <Row type="flex" justify="space-between" gutter={[10, 10]}>
        <Col flex="1">
          <Select
            placeholder="Seleccione un modelo"
            value={key}
            onChange={(e) => setKey(e)}
            style={{ marginRight: 10 }}
          >
            <Select.Option value="staffs">
              Empleados de Compañía
            </Select.Option>
          </Select>
          <Button
            type="primary"
            onClick={_handleFetchData}
            loading={loadingModel}
            style={{ marginRight: 10 }}
          >
            {loadingModel ? 'Obteniendo modelo...' : ' Obtener modelo'}
          </Button>
          <Button type="dashed" onClick={_handleClear} style={{ marginRight: 10 }}>
            Limpiar
          </Button>
          <Button
            type="primary"
            disabled={example.length === 0}
            onClick={_handleExportData}
          >
            Descargar plantilla
          </Button>
        </Col>
        <Col>
          <Button type="primary" onClick={_showModal}>
            Subir archivo
          </Button>
        </Col>
      </Row>
      <Divider />
      <div className="upload" style={{ marginTop: 20 }}>
        <h4>Reglas: </h4>
        <Row className="rules-content">
          {fields.length !== 0 ? (
            fields.map((e, i) => {
              return (
                <Descriptions size="small" title={e} key={i}>
                  {config[e].messages &&
                    config[e].messages.map((msg, j) => {
                      return (
                        <Descriptions.Item key={j}>{msg}</Descriptions.Item>
                      );
                    })}
                </Descriptions>
              );
            })
          ) : (
            <Empty style={{ margin: 'auto' }} />
          )}
        </Row>
        <Divider />
        <h4>Ejemplo: </h4>
        {fields.length !== 0 ? (
          <Table
            className="example-table"
            rowKey={() => Math.random()}
            columns={columns}
            dataSource={example}
            pagination={false}
          />
        ) : (
          <Empty />
        )}
      </div>
    </Card>
  );
};

export default ImportScreen;
