import React, { useState } from 'react';
import { Card, Button, Table, Modal, Form, message, Col, Row } from 'antd';
import { companyColumn } from '../../Share/Columns';
//Hooks
import { useFetchCompanies } from '../../Hooks/Company.hook';
import { useAuth } from '../../Hooks/Auth.hook';
//Form
import CompanyForm from '../../Components/Form/Company.form';
//API
import { createCompany, updateCompany } from '../../Services/API';
import dayjs from 'dayjs';

const Empresa = () => {
  const [
    {
      user: { _id },
      role: { rol },
    },
  ] = useAuth();
  const [companies, loading, , change, update] = useFetchCompanies([
    { field: 'user_id', value: _id },
  ]);
  const [loadingCompany, setLoadingCompany] = useState(false);
  const [company, setCompany] = useState({});
  const [formRef] = Form.useForm();
  const [modal, setModal] = useState(false);

  const onSubmit = async (values) => {
    setLoadingCompany(true);
    let response = company._id
      ? await updateCompany(company._id, values)
      : await createCompany({ ...values, status: 1, user_id: _id });
    if (response.ok) {
      formRef.resetFields();
      setModal(false);
      setCompany({});
      message.success(
        company._id ? 'Empresa actualizada' : 'Se ha agregado el registro'
      );
      update();
    } else {
      message.error(response.data.message);
    }
    setLoadingCompany(false);
  };
  return (
    <Card className="slideInLeft ">
      <Row justify="space-between">
        <Col>Empresas</Col>
        <Col>
          {rol !== 'client' && (
            <Button
              type="primary"
              style={{ marginBottom: 10 }}
              onClick={() => setModal(true)}
            >
              Agregar
            </Button>
          )}
        </Col>
      </Row>
      <Modal
        visible={modal}
        title={company._id ? 'Detalles de la empresa' : 'Nueva empresa'}
        okText={company._id ? 'Actualizar' : 'Agregar'}
        centered
        cancelText="Cancelar"
        onOk={() => formRef.submit()}
        okButtonProps={{ loading: loadingCompany }}
        onCancel={() => {
          setModal(false);
          setCompany({});
        }}
      >
        <CompanyForm formRef={formRef} onSubmit={onSubmit} />
      </Modal>
      <div>
        <Table
          columns={[
            ...companyColumn,
            {
              title: '',
              dataIndex: '_id',
              render(e, row) {
                return (
                  <Button
                    type="primary"
                    onClick={() => {
                      setModal(true);
                      formRef.setFieldsValue({
                        ...row,
                        date_registration: dayjs(row.date_registration),
                      });
                      setCompany(row);
                    }}
                  >
                    Ver empresa
                  </Button>
                );
              },
            },
          ]}
          dataSource={companies.data}
          className="responsive-table"
          locale={{ emptyText: 'Sin registros' }}
          pagination={{
            total: companies.total,
            current: companies.skip / 10 + 1,
            onChange: (e) =>
              change([{ field: 'user_id', value: _id }], (e - 1) * 10),
          }}
          rowKey={(row) => row._id}
          loading={loading}
        />
      </div>
    </Card>
  );
};

export default Empresa;
