import React, { useState, useEffect, useCallback } from 'react';
import {
  Row,
  Card,
  Form,
  Skeleton,
  message,
  Button,
  Alert,
  Result,
} from 'antd';
//Hooks
import { useNavigation } from '../../Hooks/Nav.hook';
//Forms
import ForgotForm from '../../Components/Form/Forgot.form';

import { validToken, requestLink, changePassword } from '../../Services/API';
import ResetPasswordForm from '../../Components/Form/ResetPassword.form';

const ForgotScreen = () => {
  const [valid, setValid] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [completed, setCompleted] = useState(false);
  const [subError, setSubError] = useState(false);
  const [subErrorMessage, setSubErrorMessage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loadingComplete, setLoadingComplete] = useState(false);
  const [resetLoading, setResetLoading] = useState(false);
  const [
    {
      query: { q },
    },
    nav,
  ] = useNavigation();
  const [formRef] = Form.useForm();
  const validate = useCallback(async () => {
    setLoading(true);
    let response = await validToken(q);
    if (response.ok) {
      setValid(true);
    } else {
      setError(true);
      setErrorMessage(response.data.message);
      setValid(false);
    }
    setLoading(false);
  }, [q, setError, setErrorMessage, setValid, setLoading]);
  useEffect(() => {
    if (q) {
      validate();
    }
  }, [validate, q]);
  async function request(values) {
    setLoadingComplete(true);
    let response = await requestLink(values);
    if (response.ok) {
      setCompleted(true);
      setSubError(false);
      setSubErrorMessage(null);
    } else {
      setSubError(true);
      setSubErrorMessage(response.data.message);
    }
    setLoadingComplete(false);
  }
  const _renderContent = () => {
    if (loading) {
      return <Skeleton active />;
    }
    if (!valid && error) {
      return (
        <Result
          status="error"
          title="Hubo un error"
          subTitle={errorMessage}
          extra={[
            <Button type="primary" key="console" onClick={() => nav('/')}>
              Ir a inicio
            </Button>,
          ]}
        />
      );
    }
    if (valid) {
      return (
        <div style={{ animation: 'fadeIn 200ms' }}>
          <h5>Cambiar contraseña</h5>
          <ResetPasswordForm
            navPush={nav}
            loading={resetLoading}
            onFinish={async (values) => {
              setResetLoading(true);
              let response = await changePassword(q, values);
              if (response.ok) {
                nav('/');
                message.success('Contraseña restablecida');
              } else {
                message.error(
                  'Error restableciendo la contraseña: ' + response.data.message
                );
              }
              setResetLoading(false);
            }}
          />
        </div>
      );
    }
    if (completed) {
      return (
        <Result
          status="success"
          title="Hemos enviado un email de verificación a tú correo."
          extra={[
            <Button type="primary" key="console" onClick={() => nav('/login')}>
              Iniciar Sesión
            </Button>,
          ]}
        />
      );
    }
    return (
      <div>
        <ForgotForm
          formRef={formRef}
          onSubmit={request}
          loading={loadingComplete}
        />
      </div>
    );
  };
  return (
    <div className="login-screen  fadeIn ">
      <Row
        type="flex"
        align="middle"
        justify="center"
        className="px-3 bg-white mh-page"
      >
        <Card
          className="shadow-sm rounded overflow-hidden wrapper"
          bordered={false}
          bodyStyle={{ padding: 0 }}
        >
          <div className="">
            <div className="text-center my-5">
              <span className="brand">
                <img src="/images/logo.svg" width="24px" alt="MLC" />
                <strong className="mx-1 text-black">MLC</strong>
              </span>
              <h2 className="font-weight-bold" style={{ display: 'block' }}>
                Recuperar contraseña
              </h2>
            </div>

            <div style={{ width: '80%', margin: 'auto' }}>
              {_renderContent()}
            </div>
            <div style={{ marginTop: 20 }}>
              {subError && (
                <Alert
                  type="error"
                  message={subErrorMessage}
                  closable
                  onClose={() => {
                    setSubError(false);
                    setSubErrorMessage(null);
                  }}
                />
              )}
            </div>
          </div>
        </Card>
      </Row>
    </div>
  );
};

export default ForgotScreen;
