import React from 'react';
import { Card } from 'antd';
import FilesWrapper from '../../Components/Files/FilesWrapper';

const Files = () => {
  return (
    <Card className="slideInLeft">
      <FilesWrapper />
    </Card>
  );
};

export default Files;
