import React, { useState } from 'react';
import { Menu, Avatar } from 'antd';
import { Activity, Info, Edit, Globe } from 'react-feather';

import Actividad from '../../Components/Home/Actividad';
import EditarPerfil from '../../Components/Home/EditarPerfil';
import Perfil from '../../Components/Home/Perfil';
import Empresa from '../Empresa';

//Hooks
import { useAuth } from '../../Hooks/Auth.hook';

//Utils
import { stringSeparate } from '../../Utils/parse';


const HomeScreen = () => {
  const [{ user, role }] = useAuth();
  const [key, setKey] = useState('Actividad');
  function _renderView() {
    switch (key) {
      case 'Actividad':
        return <Actividad />;
      case 'Editar':
        return <EditarPerfil />;
      case 'Perfil':
        return <Perfil />;
      default:
        return <Empresa />;
    }
  }
  return (
    <div className="home-screen">
      <div className="card-head">
        <div className="card-bg">
          <div className="flex-tw">
            <div>
              <Avatar size={70}>{stringSeparate(user.full_name)}</Avatar>
            </div>
            <div>
              <h3>{user.full_name}</h3>
              <h4>{role.rol_name || role.group}</h4>
            </div>
          </div>
          <div></div>
        </div>
        <Menu
          mode="horizontal"
          className="menu"
          selectedKeys={[key]}
          onSelect={(e) => setKey(e.key)}
        >
          <Menu.Item key="Actividad">
            <Activity className="anticon" size={14} strokeWidth={1} />
            Actividad
          </Menu.Item>
          <Menu.Item key="Perfil">
            <Info className="anticon" size={14} strokeWidth={1} />
            Perfil
          </Menu.Item>
          <Menu.Item key="Editar">
            <Edit className="anticon" size={14} strokeWidth={1} />
            Editar Perfil
          </Menu.Item>
          {role.rol === 'client' && (
            <Menu.Item key="Empresa">
              <Globe className="anticon" size={14} strokeWidth={1} />
              Empresa
            </Menu.Item>
          )}
        </Menu>
      </div>
      <div>{_renderView()}</div>
    </div>
  );
};

export default HomeScreen;
