import { useEffect, useState } from 'react';
import {
  getPathByUser,
  getPath,
  getCustomPath,
  findFiles,
} from '../Services/API';

export const useFetchUserFiles = (user_id, role = 'leader') => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [data, setData] = useState({ Contents: [], CommonPrefixes: [] });
  useEffect(() => {
    setLoading(true);
    if (role === 'admin') {
      getPathByUser(user_id).then(response => {
        if (response.ok) {
          setData(response.data);
        } else {
          setError(response.data);
        }
        setLoading(false);
      });
    } else {
      getPath().then(response => {
        if (response.ok) {
          setData(response.data);
        } else {
          setError(response.data);
        }
        setLoading(false);
      });
    }
  }, [user_id, setData, setError, setLoading, role]);
  async function update() {
    setLoading(true);
    if (role === 'admin') {
      getPathByUser(user_id).then(response => {
        if (response.ok) {
          setData(response.data);
        } else {
          setError(response.data);
        }
        setLoading(false);
      });
    } else {
      getPath().then(response => {
        if (response.ok) {
          setData(response.data);
        } else {
          setError(response.data);
        }
        setLoading(false);
      });
    }
  }
  return [data, loading, error, update];
};

const sortContents = oResponse => {
  if (oResponse) {
    if (oResponse.Contents) {
      oResponse.Contents.sort((a, b) => {
        if (a.RealName && b.RealName) {
          let textA = a.RealName.toUpperCase(),
            textB = b.RealName.toUpperCase();

          return textA < textB ? -1 : textA > textB ? 1 : 0;
        }

        return 0;
      });
    }

    if (oResponse.CommonPrefixes) {
      oResponse.CommonPrefixes.sort((a, b) => {
        let textA = a.Prefix.toUpperCase(),
          textB = b.Prefix.toUpperCase();

        return textA < textB ? -1 : textA > textB ? 1 : 0;
      });
    }
  }

  return oResponse;
};

export const useFetchUserFilesPath = (
  role,
  user_id,
  path,
  mode = 'private'
) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [data, setData] = useState({ Contents: [], CommonPrefixes: [] });
  const parse = path.replace(user_id, '');
  useEffect(() => {
    setLoading(true);
    if (mode === 'private') {
      getCustomPath(role === 'admin' ? path : parse.replace('/', '')).then(
        response => {
          if (response.ok) {
            setData(sortContents(response.data));
          } else {
            setError(response.data);
          }
          setLoading(false);
        }
      );
    } else if (mode === 'public') {
      getPath({ is_public: true, custom_path: path }).then(response => {
        if (response.ok) {
          setData(response.data);
        } else {
          setError(response.data);
        }
        setLoading(false);
      });
    } else {
      getCustomPath(user_id ? path.replace('mlc/users/', '') : undefined).then(
        response => {
          if (response.ok) {
            setData(sortContents(response.data));
          } else {
            setError(response.data);
          }
          setLoading(false);
        }
      );
    }
  }, [setData, setError, setLoading, path, parse, role, mode, user_id]);
  async function update() {
    setLoading(true);
    if (mode === 'private') {
      getCustomPath(role === 'admin' ? path : parse.replace('/', '')).then(
        response => {
          if (response.ok) {
            setData(sortContents(response.data));
          } else {
            setError(response.data);
          }
          setLoading(false);
        }
      );
    } else if (mode === 'public') {
      getPath({ is_public: true, custom_path: path }).then(response => {
        if (response.ok) {
          setData(response.data);
        } else {
          setError(response.data);
        }
        setLoading(false);
      });
    } else {
      getCustomPath(user_id ? path.replace('mlc/users/', '') : undefined).then(
        response => {
          if (response.ok) {
            setData(sortContents(response.data));
          } else {
            setError(response.data);
          }
          setLoading(false);
        }
      );
    }
  }
  async function find(name, dir) {
    setLoading(true);
    if (name && name !== '') {
      let response;

      if (mode === 'private') {
        response = await findFiles({
          name,
          path: dir,
        });
      } else if (mode === 'public') {
        response = await findFiles({
          name,
          path: 'mlc/public/',
        });
      } else {
        response = await findFiles({
          name,
          path: !path ? 'mlc/users/' : path,
        });
      }
      if (response.ok) {
        setData(sortContents(response.data));
      } else {
        setError(response.data);
      }
      setLoading(false);
    } else {
      update();
    }
  }
  return [data, loading, error, update, parse, find];
};
