import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Input, Select, Row, Col, Button } from 'antd';

const Filter = ({ handler, fields = [], selected = '_id' }) => {
  const [field, setField] = useState(fields[0]?.dataIndex);
  const [value, setValue] = useState('');
  const _handleChangeValue = e => {
    setValue(e.target.value);
  };

  const _handleChangeField = e => {
    if (e === 'status') {
      setValue(1);
    }
    setField(e);
  };

  const _handleQuery = () => {
    switch (field) {
      case 'status':
      case '_id':
        handler([{ field, value }]);
        break;
      default:
        handler([
          { field, value, operator: '$regex' },
          { field, value: 'i', operator: '$options' },
        ]);
    }
  };

  const _handleClear = () => {
    handler([], 0);
    setField(selected);
    setValue('');
  };

  const _renderInput = () => {
    switch (field) {
      case 'status':
        return (
          <Select
            value={value}
            onChange={e => setValue(e)}
            style={{ width: '100%' }}
            placeholder="Selecciona una opción"
          >
            <Select.Option value={1}>Activo</Select.Option>
            <Select.Option value={0}>Inactivo</Select.Option>
          </Select>
        );
      default:
        return (
          <Input
            placeholder="Buscar..."
            value={value}
            onChange={_handleChangeValue}
            onPressEnter={_handleQuery}
          />
        );
    }
  };
  return (
    <Row className="filter">
      <Col span={24}>
        <Row gutter={[10, 10]}>
          <Col xs={24} sm={4}>
            <Select
              defaultValue="name"
              style={{ width: '100%' }}
              value={field}
              onChange={_handleChangeField}
            >
              {fields.map(row => (
                <Select.Option key={row.dataIndex} value={row.dataIndex}>
                  {row.title}
                </Select.Option>
              ))}
            </Select>
          </Col>
          <Col xs={24} sm={8}>
            {_renderInput()}
          </Col>
          <Col xs={12} md={4} lg={2}>
            <Button style={{ width: '100%' }} onClick={_handleQuery}>
              Filtrar
            </Button>
          </Col>
          <Col xs={12} md={4} lg={2}>
            <Button
              style={{ width: '100%' }}
              type="dashed"
              onClick={_handleClear}
            >
              Limpiar
            </Button>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

Filter.propTypes = {
  fields: PropTypes.array,
  handler: PropTypes.func,
  selected: PropTypes.string,
};

export default Filter;
